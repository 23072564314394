import { DayOfWeek, DailyCheckinDaysOfWeek } from '../types'

const roles = {
  owner: 'OWNER',
  admin: 'ADMIN',
  leader: 'LEADER',
  member: 'MEMBER',
  guest: 'GUEST'
}

const rolesDisplayMap = {
  GUEST: 'Guest',
  MEMBER: 'Member',
  LEADER: 'Leader',
  ADMIN: 'Admin',
  OWNER: 'Owner'
}

const billableRoles = [roles.member, roles.leader, roles.admin, roles.owner]

export enum Entity {
  Community = 'Community',
  Space = 'Space',
  Event = 'Event'
}

export const defaultDailyCheckinDaysOfWeek: DailyCheckinDaysOfWeek = {
  [DayOfWeek.SUNDAY]: true,
  [DayOfWeek.MONDAY]: true,
  [DayOfWeek.TUESDAY]: true,
  [DayOfWeek.WEDNESDAY]: true,
  [DayOfWeek.THURSDAY]: true,
  [DayOfWeek.FRIDAY]: true,
  [DayOfWeek.SATURDAY]: true
}

export { roles, rolesDisplayMap, billableRoles }
