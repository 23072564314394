import InviteLink from '../InviteLink'
import { useModal } from '../../context/ModalContext'

function InviteLinkSection () {
  const { clearModal } = useModal()

  return (
    <div>
      <InviteLink />
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>
          Close
        </div>
      </div>
    </div>
  )
}

export default InviteLinkSection
