import PlaylistItemSelector from '../../components/PlaylistItemSelector'
import VideoPlayer from '../../components/VideoPlayer'
import { useState, useEffect } from 'react'
import { getPlaylist } from '../../shared/api'
import { useQueryClient } from '@tanstack/react-query'
import Course from '../../components/Course'
import LoadingScreen from '../../components/LoadingScreen'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'

interface PlaylistDisplayProps {
  spaceId: number;
  type: 'courses' | 'media';
}

function PlaylistDisplay ({ spaceId, type }: PlaylistDisplayProps) {
  const { currentUser } = useAuthContext()
  const [isLoading, setIsLoading] = useState(true)
  const [activeItem, setActiveItem] = useState<any>()
  const [activePlaylistItemId, setActivePlaylistItemId] = useState<any>()
  const [playlist, setPlaylist] = useState<any>()
  const queryClient = useQueryClient()

  useEffect(() => {
    const fetchPlaylist = async () => {
      const data = await queryClient.fetchQuery([`playlist:${spaceId}`], () =>
        getPlaylist({ spaceId, type })
      )

      setPlaylist(data?.program)

      if (type === 'courses') {
        const lastActiveContent = localStorage.getItem(
          `lastActiveCourse_${currentUser?.currentOrganizationId}_space_${spaceId}`
        )
        const activeItem =
          lastActiveContent !== null
            ? data?.program?.courses?.find(
              (item: any) => item?.id === parseInt(lastActiveContent)
            ) || data?.program?.courses?.[0]
            : data?.program?.courses?.[0]

        setActiveItem(activeItem)
        setActivePlaylistItemId(activeItem?.id)
      }

      if (type === 'media') {
        const lastActiveContent = localStorage.getItem(
          `lastActiveMedia_${currentUser?.currentOrganizationId}_space_${spaceId}`
        )
        const activeItem =
          lastActiveContent !== null
            ? data?.program?.media?.find(
              (item: any) => item?.id === parseInt(lastActiveContent)
            ) || data?.program?.media?.[0]
            : data?.program?.media?.[0]
        setActiveItem(activeItem)
        setActivePlaylistItemId(activeItem?.id)
      }

      setIsLoading(false)
    }

    if (spaceId) {
      void fetchPlaylist()
    }
  }, [spaceId])

  useEffect(() => {
    if (type === 'courses') {
      setActiveItem(
        playlist?.courses?.find(
          (item: any) => item?.id === activePlaylistItemId
        )
      )
    }

    if (type === 'media') {
      setActiveItem(
        playlist?.media?.find((item: any) => item?.id === activePlaylistItemId)
      )
    }
  }, [activePlaylistItemId])

  const handleNext = () => {
    if (type === 'courses') {
      const currentIndex =
        playlist?.courses?.findIndex(
          (item: any) => item?.id === activePlaylistItemId
        ) ?? 0
      if (currentIndex < playlist?.courses?.length - 1) {
        setActivePlaylistItemId(playlist?.courses?.[currentIndex + 1]?.id)
      }
    }

    if (type === 'media') {
      const currentIndex =
        playlist?.media?.findIndex(
          (item: any) => item?.id === activePlaylistItemId
        ) ?? 0
      if (currentIndex < playlist?.media?.length - 1) {
        setActivePlaylistItemId(playlist?.media?.[currentIndex + 1]?.id)
      }
    }
  }

  const handlePrevious = () => {
    if (type === 'courses') {
      const currentIndex =
        playlist?.courses?.findIndex(
          (item: any) => item?.id === activePlaylistItemId
        ) ?? 0
      if (currentIndex > 0) {
        setActivePlaylistItemId(playlist?.courses?.[currentIndex - 1]?.id)
      }
    }

    if (type === 'media') {
      const currentIndex =
        playlist?.media?.findIndex(
          (item: any) => item?.id === activePlaylistItemId
        ) ?? 0
      if (currentIndex > 0) {
        setActivePlaylistItemId(playlist?.media?.[currentIndex - 1]?.id)
      }
    }
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  const hasMultipleContent =
    (type === 'courses' && playlist?.courses?.length > 1) ||
    (type === 'media' && playlist?.media?.length > 1)

  return (
    <>
      {playlist && (
        <div className="playlist-container">
          {hasMultipleContent && (
            <div className="selector-wrapper">
              <PlaylistItemSelector
                spaceId={spaceId}
                publisherName={playlist?.publisherName}
                playlist={
                  type === 'courses' ? playlist?.courses : playlist?.media
                }
                activePlaylistItemId={activePlaylistItemId}
                setActivePlaylistItemId={setActivePlaylistItemId}
                type={type}
              />
            </div>
          )}
          <div className="content-wrapper">
            {activeItem?.courseStatus === 'PUBLIC' ||
            activeItem?.courseStatus === 'PRIVATE'
              ? (
              <Course
                spaceId={spaceId}
                courseName={activeItem?.name}
                courseType={activeItem?.courseType}
                handleNext={
                  playlist?.courses?.findIndex(
                    (item: any) => item?.id === activePlaylistItemId
                  ) <
                  playlist?.courses?.length - 1
                    ? handleNext
                    : undefined
                }
                handlePrevious={
                  playlist?.courses?.findIndex(
                    (item: any) => item?.id === activePlaylistItemId
                  ) > 0
                    ? handlePrevious
                    : undefined
                }
                courseVersionId={activeItem?.courseVersion?.[0]?.id}
              />
                )
              : (
              <VideoPlayer
                playlistItem={activeItem}
                handleNext={
                  playlist?.media?.findIndex(
                    (item: any) => item?.id === activePlaylistItemId
                  ) <
                  playlist?.media?.length - 1
                    ? handleNext
                    : undefined
                }
                handlePrevious={
                  playlist?.media?.findIndex(
                    (item: any) => item?.id === activePlaylistItemId
                  ) > 0
                    ? handlePrevious
                    : undefined
                }
                hasMultipleContent={hasMultipleContent}
              />
                )}
          </div>
        </div>
      )}
    </>
  )
}

export default PlaylistDisplay
