import { currentDateTime } from '../../shared/dates'
import { Event as EventType } from '../../types/DataTypes'
import Event from './Event'
import './index.css'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'

function EventsList ({
  events,
  showSpaceBadge = true,
  handleJoinEvent
}: {
  events: EventType[];
  showSpaceBadge?: boolean;
  handleJoinEvent: (event: EventType) => void;
}) {
  const [showEventSettingsMenu, setShowEventSettingsMenu] = useState('')

  const sortedEvents: Record<string, EventType[]> = {
    previous: [],
    upcoming: [],
    current: []
  }

  const eventTiles = {
    happeningNow: 'Happening Now',
    upcoming: 'Upcoming Events',
    previous: 'Past Events'
  }

  events.forEach((event) => {
    const now = currentDateTime()
    const startTime = new Date(event.startTime).getTime()
    const endTime = event.endTime ? new Date(event.endTime).getTime() : null

    if (endTime && endTime <= now) {
      sortedEvents.previous.push(event)
    } else if (startTime <= now && (!endTime || endTime > now)) {
      sortedEvents.current.push(event)
    } else {
      sortedEvents.upcoming.push(event)
    }
  })

  const renderEventSection = (title: string, eventList: EventType[]) => {
    if (eventList.length === 0) {
      return null
    }

    if (title === eventTiles.previous && eventList.length > 4) {
      const displayedEvents = eventList.slice(0, 4)
      const remainingEvents = eventList.slice(4)

      return (
        <div className="event-section">
          <h2 style={{ fontSize: '1.15rem' }}>{title}</h2>
          <div className="event-items">
            {displayedEvents.map((event) => (
              <Event
                key={event.id}
                event={event}
                showSettings={false}
                showSpaceBadge={showSpaceBadge}
                onEventClick={handleJoinEvent}
                showEventSettingsMenu={showEventSettingsMenu}
                setShowEventSettingsMenu={setShowEventSettingsMenu}
              />
            ))}
            {remainingEvents.length > 0 && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: 'var(--primary-font-color)' }} />}
                aria-controls="previous-events-content"
                id="previous-events-header"
              >
                <Typography>Show {remainingEvents.length} More Events</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {remainingEvents.map((event) => (
                  <div key={event.id} style={{ margin: '1rem 0' }}>
                    <Event
                      event={event}
                      showSettings={false}
                      showSpaceBadge={showSpaceBadge}
                      onEventClick={handleJoinEvent}
                      showEventSettingsMenu={showEventSettingsMenu}
                      setShowEventSettingsMenu={setShowEventSettingsMenu}
                    />
                  </div>
                ))}
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className="event-section">
        <h2 style={{ fontSize: '1.15rem' }}>{title}</h2>
        <div className="event-items">
          {eventList.map((event) => (
            <Event
              key={event.id}
              event={event}
              showSettings={title === eventTiles.upcoming}
              showSpaceBadge={showSpaceBadge}
              onEventClick={handleJoinEvent}
              showEventSettingsMenu={showEventSettingsMenu}
              setShowEventSettingsMenu={setShowEventSettingsMenu}
            />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="events-list">
      {renderEventSection(eventTiles.happeningNow, sortedEvents.current)}
      {renderEventSection(eventTiles.upcoming, sortedEvents.upcoming)}
      {renderEventSection(eventTiles.previous, sortedEvents.previous)}
    </div>
  )
}

export default EventsList
