import './index.css'
import { useAuthContext } from '../../context/AuthContext'
import { SpaceRole, SpaceTitle } from '../../types'
import { getSpaceLeaderPolicy } from '../../shared/policies'
import { isAuthorized } from '../../shared/permissions'

interface ManageSpaceSpaceRolesProps {
  spaceRole: SpaceRole
  selectRole: ({ title, spaceRoleId }: { title: SpaceTitle, spaceRoleId: number }) => void
}

function ManageSpaceSpaceRoles ({ spaceRole, selectRole }: ManageSpaceSpaceRolesProps) {
  const { currentUser } = useAuthContext()

  const isGuest = spaceRole?.organizationRole?.title === 'GUEST'
  const isSpaceLeader = isAuthorized(getSpaceLeaderPolicy(spaceRole?.spaceId, currentUser?.currentOrganizationId), currentUser)

  return (
    <div className="role-container">
      <select disabled={!isSpaceLeader} value={spaceRole.title} onChange={(e) => selectRole({ title: e.target.value as SpaceTitle, spaceRoleId: spaceRole.id })}>
        <option value="MEMBER">Participant</option>
        {!isGuest && <option value="LEADER">Host</option>}
      </select>
    </div>
  )
}

export default ManageSpaceSpaceRoles
