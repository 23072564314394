import { useState, useRef } from 'react'
import { useUpdateOrganizationMutation } from '../../../shared/queryHooks'
import { useAuthContext } from '../../../context/AuthContext'
import { PuffLoader } from 'react-spinners'
import { getCurrentOrganization } from '../../../shared/utilities'
import { generateAvatarUploadUrlForOrganization } from '../../../shared/api'
import { useQueryClient } from '@tanstack/react-query'
import { postAvatarUrl } from '../../../shared/s3'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import AddEditAvatar from '../../../components/AddEditAvatar'
import { ImageListType } from 'react-images-uploading'
import './index.css'
import { Entity } from '../../../shared/enums'

function OrganizationSettingsGeneral () {
  const { currentUser } = useAuthContext()
  const queryClient = useQueryClient()
  const editOrganization = useUpdateOrganizationMutation()
  const [hasChangedAvatar, setHasChangedAvatar] = useState(false)
  const editor = useRef<any>(null)
  const currentOrganization = getCurrentOrganization(currentUser)
  const logoUrl = currentOrganization?.logoUrl
  const [avatar, setAvatar] = useState<ImageListType>(logoUrl ? [{ data_url: logoUrl }] : [])
  const [name, setName] = useState<string>(currentOrganization?.name ?? '')
  const [domain, setDomain] = useState<string>(currentOrganization?.domain ?? '')

  async function submitForm () {
    try {
      if (avatar.length > 0 && editor.current?.props?.image) {
        const { url, uploadUrl } = await generateAvatarUploadUrlForOrganization()
        if (!url || !uploadUrl) {
          throw new Error('Error generating avatar upload url')
        }
        await postAvatarUrl({ avatarUploadUrl: uploadUrl, editor })
        await editOrganization.mutateAsync({
          name,
          domain,
          logoUrl: url,
          organizationId: currentUser?.currentOrganizationId ?? 0,
          memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
          defaultSpace: currentOrganization?.defaultSpace ?? false,
          shareContactData: currentOrganization?.shareContactData ?? false,
          membersCanInvite: currentOrganization?.membersCanInvite ?? false
        })
      } else {
        await editOrganization.mutateAsync({
          name,
          domain,
          logoUrl: currentOrganization?.logoUrl ?? '',
          organizationId: currentUser?.currentOrganizationId ?? 0,
          memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
          defaultSpace: currentOrganization?.defaultSpace ?? false,
          shareContactData: currentOrganization?.shareContactData ?? false,
          membersCanInvite: currentOrganization?.membersCanInvite ?? false
        })
      }
      setHasChangedAvatar(false)
      await queryClient.invalidateQueries({ queryKey: ['user'] })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <SecondaryNavBar title="General" />
      <div className="profile-settings-wrapper org-nav-settings">
        <div className="profile-setting-container">
          <div className="profile-fields-container" data-testid="organization-general-settings">
            <div key="Name">
              <label>{Entity.Community} name</label>
              <input
                type="text"
                data-testid="organization-name-field"
                placeholder="Example Community"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div key="Website">
              <label>{Entity.Community} website</label>
              <input
                type="text"
                data-testid="organization-domain-field"
                placeholder="example.com"
                onChange={(e) => setDomain(e.target.value)}
                value={domain}
              />
            </div>
            <div key="logo">
              <AddEditAvatar
                setAvatar={setAvatar}
                setHasChangedAvatar={setHasChangedAvatar}
                avatar={avatar}
                editor={editor}
                hasChangedAvatar={hasChangedAvatar}
                firstName={name}
                organizationAvatarFrame={true}
              />
            </div>
            <div className="button footer-button" data-testid="edit-save-button" onClick={() => submitForm()}>
              {editOrganization.isLoading ? <PuffLoader color="#fff" size={21} /> : 'Save'}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrganizationSettingsGeneral
