import { useRef } from 'react'
import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import { useModal } from '../../context/ModalContext'
import AddOrEditSpaceroles from '../../modals/AddOrEditSpaceroles'
import CreateOrEditSpace from '../../modals/CreateOrEditSpace'
import ActivateOrArchiveSpace from '../../modals/ActivateOrArchiveSpace'

function OrganizationSpaceSettingsMenuItems ({
  useOutsideAlerter,
  space,
  setSpaceSettingMenu
}) {
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setSpaceSettingMenu })
  const { makeModal } = useModal()
  const buttonText = `${space.isArchived ? 'Restore' : 'Archive'}`
  const actionClass = !space.isArchived ? 'danger' : ''

  return (
    <div className="goal-settings dropdown" ref={wrapperRef}>
      <Paper sx={{ width: 175 }} elevation={24}>
        <MenuList dense ref={wrapperRef}>
          <div>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                makeModal({
                  modal: (
                    <AddOrEditSpaceroles existingSpace={space} showAllMembers />
                  ),
                  title: `Space Members for ${space?.name}`
                })
              }}
            >
              <ListItemText>Edit Members</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                makeModal({
                  modal: <CreateOrEditSpace existingSpace={space} />,
                  title: 'Edit Space'
                })
              }}
            >
              <ListItemText>Edit Space</ListItemText>
            </MenuItem>
            {!space.defaultSpace && (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation()
                  makeModal({
                    modal: <ActivateOrArchiveSpace space={space} />,
                    title: buttonText
                  })
                }}
              >
              <ListItemText>
                <span className={actionClass}>{buttonText}</span>
              </ListItemText>
              </MenuItem>
            )}
          </div>
        </MenuList>
      </Paper>
    </div>
  )
}

export default OrganizationSpaceSettingsMenuItems
