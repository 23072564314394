import PlaylistDisplay from '../../../components/PlaylistDisplay'

interface PlaylistProps {
  spaceId: number;
  type: 'courses' | 'media';
}

function Playlist ({ spaceId, type }: PlaylistProps) {
  return (
    <PlaylistDisplay spaceId={spaceId} type={type} />
  )
}

export default Playlist
