import { useResendInvitationMutation } from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import './index.css'
import { PuffLoader } from 'react-spinners'
import { OrganizationRole } from '../../types'

interface ResendInviteProps {
  person: OrganizationRole;
}

function ResendInvite ({ person }: ResendInviteProps) {
  const { clearModal } = useModal()
  const resendInvite = useResendInvitationMutation()

  const handleSave = () => {
    if (person?.invitationCode) {
      resendInvite.mutate(person?.invitationCode)
    }
  }

  return (
    <>
      <div className="person-settings-modal-content">
        <p>
          You are about to resend and invitation to{' '}
          <span className="modal-username">
            {person?.contact?.firstName} {person?.contact?.lastName}
          </span>{' '}
          at {person?.contact?.email}.
        </p>
        <p>This invite will be valid for 14 days.</p>
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>
          Cancel
        </div>
        <div
          data-testid="toggle-activation-button"
          className="button"
          onClick={handleSave}
        >
          {resendInvite.isLoading
            ? (
            <PuffLoader color="#fff" size={21} />
              )
            : (
                'Resend Invite'
              )}
        </div>
      </div>
    </>
  )
}

export default ResendInvite
