import { useRef } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import './index.css'
import {
  isYouTubeUrl,
  getYouTubeVideoId,
  isVimeoUrl,
  getVimeoVideoId
} from '../../shared/utilities'
import EmptyHero from '../../components/EmptyHero'
import emptyIllustration from '../../assets/pathway.svg'

interface VideoPlayerProps {
  playlistItem: {
    url: string;
    name: string;
  };
  handleNext?: () => void;
  handlePrevious?: () => void;
  hasMultipleContent: boolean;
}

function VideoPlayer ({
  playlistItem,
  handleNext,
  handlePrevious,
  hasMultipleContent
}: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const isYoutube = isYouTubeUrl(playlistItem.url)
  const isVimeo = isVimeoUrl(playlistItem.url)

  if (!playlistItem?.url) {
    return (
      <EmptyHero
        image={emptyIllustration}
        title="No Content Found"
        description="There are no content items in this playlist."
      />
    )
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'space-between',
          marginBottom: '1rem',
          width: '100%',
          maxWidth: hasMultipleContent ? '700px' : '1000px'
        }}
      >
        <div
          className="course-toggle"
          onClick={handlePrevious}
          style={{ visibility: handlePrevious ? 'visible' : 'hidden' }}
        >
          <ChevronLeftIcon />
        </div>
        <div className="course-name">{playlistItem?.name}</div>
        <div
          className="course-toggle"
          onClick={handleNext}
          style={{ visibility: handleNext ? 'visible' : 'hidden' }}
        >
          <ChevronRightIcon />
        </div>
      </div>
      <div className="video-player-container">
        {isYoutube
          ? (
          <iframe
            width="100%"
            src={`https://www.youtube.com/embed/${getYouTubeVideoId(
              playlistItem?.url
            )}`}
            allow="encrypted-media; autoplay"
            allowFullScreen
            className="youtube-vimeo-element"
          />
            )
          : isVimeo
            ? (
          <iframe
            width="100%"
            height="100%"
            src={`https://player.vimeo.com/video/${getVimeoVideoId(
              playlistItem?.url
            )}`}
            allow="fullscreen; autoplay"
            allowFullScreen
            className="youtube-vimeo-element"
          />
              )
            : (
          <video ref={videoRef} src={playlistItem?.url} controls />
              )}
      </div>
    </>
  )
}

export default VideoPlayer
