import './index.css'
import { OrganizationRole, SpaceRole } from '../../types'
import { isAuthorized } from '../../shared/permissions'
import { getSpaceLeaderPolicy } from '../../shared/policies'
import { useAuthContext } from '../../context/AuthContext'

interface ManageUserSpaceRolesProps {
  spaceRole: SpaceRole
  selectRole: (title: string) => void,
  person: OrganizationRole
}

function ManageUserSpaceRoles ({ spaceRole, selectRole, person }: ManageUserSpaceRolesProps) {
  const { currentUser } = useAuthContext()
  const isSpaceLeader = isAuthorized(getSpaceLeaderPolicy(spaceRole?.spaceId, currentUser?.currentOrganizationId), currentUser)
  const isGuest = person?.title === 'GUEST'

  return (
    <div className="role-container">
      <select value={spaceRole.title} onChange={(e) => selectRole(e.target.value)}>
        <option value="MEMBER">Participant</option>
        {!isGuest && isSpaceLeader && <option value="LEADER">Host</option>}
      </select>
    </div>
  )
}

export default ManageUserSpaceRoles
