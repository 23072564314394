import { getSpaceLeaderPolicy } from '../../../shared/policies'
import { useAuthContext } from '../../../context/AuthContext'
import { isAuthorized } from '../../../shared/permissions'
import { useModal } from '../../../context/ModalContext'
import StartOrScheduleConnection from '../../../modals/StartOrScheduleConnection'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Button from '../../../components/Button'
import { Entity } from '../../../shared/enums'
import { Space, EventTag, Event } from '../../../types'
import './index.css'
import EventsList from '../../../components/EventsList'
import { convertConnectionToEvent } from '../../../shared/utilities'
import { useJoinEventMutation } from '../../../shared/queryHooks'
import { useNavigate } from 'react-router-dom'

interface ConnectionsProps {
  inOrganizationSettings: boolean
  space: Space
  tags: EventTag[]
}

function Connections ({ inOrganizationSettings = false, space, tags }: ConnectionsProps) {
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const isSpaceLeader = isAuthorized(getSpaceLeaderPolicy(space.id, currentUser?.currentOrganizationId), currentUser)

  function startConnection () {
    makeModal({
      modal: <StartOrScheduleConnection space={space} tags={tags} />,
      title: `New ${Entity.Event} for ${space?.name}`,
      disableFocus: true,
      modalSize: 'LARGE'
    })
  }

  const navigate = useNavigate()
  const joinEvent = useJoinEventMutation()

  function handleJoinEvent (event: Event) {
    joinEvent.mutate({ eventUid: event.uid })
    navigate(`/connections/${event.uid}`)
  }

  return (
    <div className="connection-history-container">
      {!inOrganizationSettings && isSpaceLeader && (
          <Button
            className="button button-new-connect"
            onClick={startConnection}
          >
            <AddRoundedIcon /> Create {Entity.Event}
          </Button>
      )}
      {inOrganizationSettings && space?.connections?.length === 0 && (
        <div className="space-no-connections">
          There are no {Entity.Event}s in this space.
        </div>
      )}
      <EventsList
        events={space?.connections?.map(convertConnectionToEvent).filter(event => event !== null) ?? []}
        showSpaceBadge={false}
        handleJoinEvent={handleJoinEvent}
      />
    </div>
  )
}

export default Connections
