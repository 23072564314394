import { useState } from 'react'
import { Space } from '../../types'
import { PuffLoader } from 'react-spinners'
import { useEditSpaceMutation, useQueryClient } from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import { useAuthContext } from '../../context/AuthContext'

function ManageAbout ({ existingSpace }: { existingSpace: Space }) {
  const [space, setSpace] = useState(existingSpace)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const editSpace = useEditSpaceMutation()
  const queryClient = useQueryClient()
  const { clearModal } = useModal()
  const { currentUser } = useAuthContext()

  const setAttributeHandling = ({ value, attribute }) => {
    setSpace((previous) => ({ ...previous, [attribute]: value }))
    if (attribute === 'name') {
      setErrorMessage('')
    }
    if (attribute === 'purpose') {
      if (value.length >= 144) {
        setErrorMessage("The spaces's purpose cannot be more than 144 characters")
      } else {
        setErrorMessage('')
      }
    }
  }

  const handleSubmit = async () => {
    if (space?.name.trim() === '') {
      setErrorMessage('Please enter a space name')
      return
    }

    await editSpace.mutateAsync(space)
    await queryClient.invalidateQueries(['connections'])
    await queryClient.invalidateQueries([`organization:${currentUser?.currentOrganizationId}`, 'spaces'])
    clearModal()
  }

  return (
    <>
     {errorMessage && <div className="addEdit-space-error">{errorMessage}</div>}
     <div className="program-dropdown-container">
        <label>Name</label>
        <input
          type="text"
          placeholder="Space name"
          value={space.name}
          maxLength={50}
          onChange={(e) => setAttributeHandling({ value: e.target.value, attribute: 'name' })}
        />
      </div>
      <div className="program-dropdown-container">
        <label>Topic</label>
        <input
          type="text"
          placeholder="What is this Space about?"
          maxLength={144}
          value={space.purpose ?? ''}
          onChange={(e) => setAttributeHandling({ value: e.target.value, attribute: 'purpose' })}
        />
      </div>
      <div className="modal-footer" style={{ marginTop: '8.8rem' }}>
        <div className="button secondary" onClick={clearModal}>Cancel</div>
        <div className="button start-connection-button" onClick={handleSubmit}>{editSpace.isLoading ? <PuffLoader color="#fff" size={20} /> : 'Save'}</div>
      </div>
    </>
  )
}

export default ManageAbout
