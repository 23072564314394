import { Box, Select, MenuItem } from '@mui/material'
import TextField from '@mui/material/TextField'
import Post from '../Post'
import { useState, useEffect, useCallback, useRef } from 'react'
import './index.css'
import { useAuthContext } from '../../context/AuthContext'
import {
  usePostsQuery,
  usePostsBySpaceQuery,
  useCreatePostMutation,
  useSpacesQuery
} from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { PuffLoader } from 'react-spinners'
import PostPreview from '../PostPreview'
import { getLinkPreview } from 'link-preview-js'
import { isImageUrl, isVideoUrl } from '../../shared/utilities'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import { toast } from 'sonner'
import Button from '../Button'
import Avatar from '../Avatar'
import PublicIcon from '@mui/icons-material/Public'
import { GridViewRounded } from '@mui/icons-material'
import LoadingScreen from '../LoadingScreen'

interface PostPreviewData {
  title: string | null;
  description: string | null;
  image: string | null;
  url: string | null;
  type: 'link' | 'image' | 'video';
}

function PostFeed ({ spaceId }: { spaceId?: number }) {
  const inputRef = useRef<HTMLInputElement>(null)

  const [newPost, setNewPost] = useState('')
  const [selectedSpaceId, setSelectedSpaceId] = useState<number | null>(null)
  const [postPreviews, setPostPreviews] = useState<PostPreviewData[]>([])
  const { currentUser } = useAuthContext()
  const loadingRef = useRef(null)
  const isAdminOrOwner = isAuthorized(
    getOrganizationAdminPolicy(currentUser?.currentOrganizationId),
    currentUser
  )

  const {
    isLoading: isSpaceLoading,
    isError: isSpaceError,
    data: spaces
  } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, {
    enabled: !!currentUser,
    filter: 'where-member'
  })
  const queryHook = spaceId ? usePostsBySpaceQuery : usePostsQuery
  const {
    data: posts,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    error
  } = queryHook(spaceId ?? 0, {
    enabled: !!currentUser && !!spaces
  })

  const createPostMutation = useCreatePostMutation()

  const handleCreatePost = useCallback(async () => {
    if (!spaceId && !selectedSpaceId && !isAdminOrOwner) {
      toast.error('Select a space to post')
      return
    }
    await createPostMutation.mutateAsync({
      content: newPost,
      spaceId: spaceId ?? (selectedSpaceId || undefined)
    })
    if (!isAdminOrOwner && spaces?.length) {
      setSelectedSpaceId(spaces[0].id)
    } else {
      setSelectedSpaceId(null)
    }
    setNewPost('')
    setPostPreviews([])
    setIsInputBoxFocused(false)
  }, [newPost, spaceId, selectedSpaceId, createPostMutation])

  const handleInputChange = useCallback(async (e) => {
    const content = e.target.value
    setNewPost(content)

    const urls = content.match(/https?:\/\/[^\s]+/g)
    if (urls) {
      const newPreviews: PostPreviewData[] = []
      for (const url of urls) {
        try {
          let preview: PostPreviewData
          if (await isImageUrl(url)) {
            preview = {
              title: null,
              description: null,
              image: url,
              url,
              type: 'image'
            }
          } else if (await isVideoUrl(url)) {
            preview = {
              title: null,
              description: null,
              image: null,
              url,
              type: 'video'
            }
          } else {
            const linkPreview = (await getLinkPreview(url)) as any
            preview = {
              title: linkPreview?.title || null,
              description: linkPreview?.description || null,
              image: linkPreview?.images?.[0] || null,
              url: linkPreview?.url || url,
              type: 'link'
            }
          }
          newPreviews.push(preview)
        } catch (error) {
          logError('Error fetching link preview:')
        }
      }
      setPostPreviews(newPreviews)
    } else {
      setPostPreviews([])
    }
  }, [])

  const handleObserver = useCallback(
    async (entries: IntersectionObserverEntry[]) => {
      const target = entries[0]
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        await fetchNextPage()
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  )

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0
    }
    const observer = new IntersectionObserver(handleObserver, option)
    if (loadingRef.current) observer.observe(loadingRef.current)
    return () => {
      if (loadingRef.current) observer.unobserve(loadingRef.current)
    }
  }, [handleObserver])

  const handleKeyDown = useCallback(
    async (e: React.KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault()
        if (newPost.length > 0) {
          await handleCreatePost()
        }
      }
    },
    [newPost, handleCreatePost]
  )

  useEffect(() => {
    if (!isAdminOrOwner && spaces?.length && !selectedSpaceId && !spaceId) {
      setSelectedSpaceId(spaces[0].id)
    }
  }, [spaces, isAdminOrOwner, selectedSpaceId, spaceId])

  const [isInputBoxFocused, setIsInputBoxFocused] = useState(false)

  const handleInputBoxClick = useCallback(() => {
    setIsInputBoxFocused(true)
    inputRef.current?.focus()
  }, [])

  const handleInputBoxBlur = useCallback((e: React.FocusEvent) => {
    if (
      !e.currentTarget.contains(e.relatedTarget as Node) &&
      e.relatedTarget?.className !== 'button post-feed_input-box-button'
    ) {
      setIsInputBoxFocused(false)
    }
  }, [])

  if (isLoading || isSpaceLoading) {
    return <LoadingScreen />
  }

  if (isError || isSpaceError) {
    logError(error)
  }

  const emptyText = `There are not posts ${
    spaceId ? 'for this space' : ''
  } available`

  const spaceSelectUnavailable =
    spaces?.length === 0 && !isAdminOrOwner && !spaceId

  return (
    <Box className={`post-feed ${spaceId ? 'space-feed' : ''}`}>
      <Box className="post-feed_input-container" mb={3}>
        <Box
          className={`post-feed_input-box ${
            isInputBoxFocused ? 'is-focused' : ''
          }`}
          onClick={handleInputBoxClick}
          onBlur={handleInputBoxBlur}
          tabIndex={-1}
        >
          <div
            className="post-feed_input-box-inner"
            onClick={() => inputRef.current?.focus()}
          >
            <div style={{ display: 'flex' }}>
              <Avatar
                className="post-avatar"
                firstName={currentUser?.firstName}
                lastName={currentUser?.lastName}
                avatar={currentUser?.avatar}
              />
              <TextField
                placeholder="What's on your mind?"
                value={newPost}
                onChange={handleInputChange}
                className="post-feed_input-field"
                multiline
                fullWidth
                variant="standard"
                inputRef={inputRef}
                InputProps={{
                  disableUnderline: true
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
            <Box
              display="flex"
              style={{
                marginLeft: '2.75rem',
                marginBottom: '0.5rem',
                marginTop: '0.25rem',
                gap: '0.5rem'
              }}
            >
              {postPreviews.map((preview, index) => (
                <Box key={index} position="relative" width={200}>
                  <PostPreview
                    {...preview}
                    index={index}
                    isPreview={true}
                    allUrls={postPreviews.map((p) => p.url ?? '')}
                  />
                </Box>
              ))}
            </Box>
          </div>
          <div className="post-feed-input-actions">
            {!spaceId && ((spaces?.length ?? 0) > 0 || isAdminOrOwner) && (
              <Select
                value={selectedSpaceId || 0}
                onChange={(e) =>
                  setSelectedSpaceId(e.target.value as number | null)
                }
                displayEmpty
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {!selected && isAdminOrOwner
                      ? (
                      <PublicIcon fontSize="small" />
                        )
                      : (
                      <GridViewRounded fontSize="small" />
                        )}
                    {!selected
                      ? isAdminOrOwner
                        ? 'Everyone'
                        : 'Select a space'
                      : spaces?.find((space) => space.id === selected)?.name}
                  </Box>
                )}
              >
                {isAdminOrOwner && (
                  <MenuItem value={0}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <PublicIcon fontSize="small" />
                      Everyone
                    </Box>
                  </MenuItem>
                )}
                {spaces?.map((space) => (
                  <MenuItem key={space?.id} value={space?.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <GridViewRounded fontSize="small" />
                      {space?.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            )}
            <Button
              onClick={handleCreatePost}
              className="button post-feed_input-box-button"
              isDisabled={newPost.length === 0 || spaceSelectUnavailable}
            >
              Post
            </Button>
          </div>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        {posts?.pages?.map((page, index) => (
          <Box key={index} display="flex" flexDirection="column" gap={1} mt={1}>
            {page?.posts?.map((post) => (
              <Post
                key={post.id}
                post={post}
                liveEvents={page.liveEvents}
                showSpaceBadge={!spaceId}
              />
            ))}
          </Box>
        ))}
        {posts && posts?.pages?.length === 0 && <div>{emptyText}</div>}
        {(isFetchingNextPage || hasNextPage) && (
          <Box ref={loadingRef} display="flex" justifyContent="center" my={2}>
            <PuffLoader size={60} color="#fff" />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default PostFeed
