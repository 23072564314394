import { Outlet, useLocation } from 'react-router-dom'
import NavBar from '../../components/NavBar'
import TrialEndingBanner from '../../components/TrialEndingBanner'
import useLayoutDetector from '../../shared/useLayoutDetector'

function Layout ({ currentUser, displayTrialBanner, setDisplayTrialBanner }) {
  useLayoutDetector()
  const location = useLocation()
  const isHomePage = location.pathname === '/'

  return (
    <div className={`app ${displayTrialBanner ? 'app-trial-banner-shown' : ''} ${isHomePage ? 'home-page' : ''}`}>
      <TrialEndingBanner
        currentUser={currentUser}
        displayTrialBanner={displayTrialBanner}
        setDisplayTrialBanner={setDisplayTrialBanner}
      />
      <NavBar />
      <div className="app-content">
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
