const stage = process.env.REACT_APP_STAGE

type Config = {
  SENTRY_DSN: string;
  GOOGLE_API_KEY: string;
  CRISP_WEBSITE_ID: string;
  STRIPE_API_KEY: string;
  API_HOST: string;
  WEBSOCKET_HOST: string;
  CDN_HOST: string;
  POSTHOG_API_KEY: string;
  STUDIO_HOST: string;
  APP_HOST: string;
  FREEDOM_CHECK_API_HOST: string; // TODO: remove this
};

const CONFIG_PROD: Config = {
  SENTRY_DSN: 'https://e9f042fd28bf4a7cb1d2dcbb09e63489@o1254450.ingest.sentry.io/6422340',
  GOOGLE_API_KEY: 'AIzaSyCx8PnlrnixdMSn7qja_SjE2GhpltbqdDw',
  CRISP_WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40',
  STRIPE_API_KEY: 'pk_live_51LuLuGDGl4W5zqduyGL3qRtuNdJh4Z3CCzj0FMeJRGBmQXLHYt5abC9qvY9QvsccVBc40PeQfxF3Nod9b6qP58oT00rdcIJyqy',
  API_HOST: 'https://api.connectbetter.io',
  WEBSOCKET_HOST: 'wss://api.connectbetter.io',
  CDN_HOST: 'https://cdn.connectbetter.io',
  POSTHOG_API_KEY: 'phc_hcvjmgJa6ZuOpsRzx18nEiPuavncFTCN9jNNQVNgVcH',
  STUDIO_HOST: 'https://studio.connectbetter.io',
  APP_HOST: 'https://app.connectbetter.io',
  FREEDOM_CHECK_API_HOST: 'https://wlvjr3etqc.execute-api.us-east-1.amazonaws.com'
}

const CONFIG_STAGING: Config = {
  SENTRY_DSN: '',
  GOOGLE_API_KEY: 'AIzaSyCx8PnlrnixdMSn7qja_SjE2GhpltbqdDw',
  CRISP_WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40',
  STRIPE_API_KEY: 'pk_test_51LuLuGDGl4W5zqduK8MGiWERthFT51oFudeb8u42QyJchWjuqWEi7Vd6wWk59dUdCXQ4BmTxaGYSdau2ygHJoPui00HhaPYipf',
  API_HOST: 'https://staging-api.connectbetter.io',
  WEBSOCKET_HOST: 'wss://staging-api.connectbetter.io',
  CDN_HOST: 'https://staging-cdn.connectbetter.io',
  POSTHOG_API_KEY: '',
  STUDIO_HOST: 'https://staging-studio-v2.connectbetter.io',
  APP_HOST: 'https://staging-app.connectbetter.io',
  FREEDOM_CHECK_API_HOST: 'https://wlvjr3etqc.execute-api.us-east-1.amazonaws.com'
}

const CONFIG_DEV: Config = {
  SENTRY_DSN: '',
  GOOGLE_API_KEY: 'AIzaSyCx8PnlrnixdMSn7qja_SjE2GhpltbqdDw',
  CRISP_WEBSITE_ID: '',
  STRIPE_API_KEY: 'pk_test_51LuLuGDGl4W5zqduK8MGiWERthFT51oFudeb8u42QyJchWjuqWEi7Vd6wWk59dUdCXQ4BmTxaGYSdau2ygHJoPui00HhaPYipf',
  API_HOST: 'http://localhost:4000',
  WEBSOCKET_HOST: 'ws://localhost:4000',
  CDN_HOST: 'https://staging-cdn.connectbetter.io',
  POSTHOG_API_KEY: '',
  STUDIO_HOST: 'http://localhost:3002',
  APP_HOST: 'http://localhost:3000',
  FREEDOM_CHECK_API_HOST: 'https://wlvjr3etqc.execute-api.us-east-1.amazonaws.com'
}

const getConfigForEnvironment = () => {
  if (stage === 'production') {
    return CONFIG_PROD
  } else if (stage === 'staging') {
    return CONFIG_STAGING
  } else {
    return CONFIG_DEV
  }
}

const config = getConfigForEnvironment()

export { config }
