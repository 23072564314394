import { useUpdateActionMutation } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { Checkbox } from '@mui/material'
import { useModal } from '../../context/ModalContext'
import {
  isYouTubeUrl,
  isVimeoUrl,
  isValidVideoFileUrl,
  convertConnectionToEvent,
  onboardingActions
} from '../../shared/utilities'
import { useNavigate } from 'react-router-dom'
import ActionSettingsMenu from '../ActionSettingsMenu'
import DailyCheckin from '../../modals/DailyCheckin'
import ListExercisesItem from '../ListExercisesItem'
import './index.css'
import { Action as ActionType, Exercise, ActionTypes } from '../../types'
import Event from '../EventsList/Event'
import { config } from '../../shared/config'

interface ActionProps {
  action: ActionType;
  organizationRoleId?: number;
  viewOnly?: boolean;
  actionExercises?: Exercise[];
  tags?: any[];
}

function Action ({
  action,
  organizationRoleId,
  viewOnly = false,
  actionExercises
}: ActionProps) {
  const completeAction = useUpdateActionMutation()
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const navigate = useNavigate()

  const exercise = actionExercises?.find(
    (exercise) => exercise?.id === parseInt(action?.description)
  )
  const isVideoUrl =
    isYouTubeUrl(exercise?.url ?? '') ||
    isVimeoUrl(exercise?.url ?? '') ||
    isValidVideoFileUrl(exercise?.url ?? '')
  const nextStatus =
    action?.actionType !== ActionTypes.EXERCISE
      ? action.status === 'COMPLETED'
        ? 'UNCOMPLETED'
        : 'COMPLETED'
      : 'REVIEW'
  const isCompletedOrReview =
    action?.status === 'COMPLETED' || action?.status === 'REVIEW'

  function getDailyCheckin ({ description, actionId }) {
    makeModal({
      modal: <DailyCheckin actionId={actionId} />,
      title: ` ${description}`
    })
  }

  function navigateToStudio () {
    window.open(`${config.STUDIO_HOST}/?organization=${currentUser?.currentOrganizationId}`, '_blank')
  }

  if (action?.actionType === ActionTypes.CONNECT) {
    return (
      <Event
        event={convertConnectionToEvent(action?.connection)}
        showSettings={false}
        showSpaceBadge={true}
        onEventClick={(event) => navigate(`/connections/${event.uid}`)}
      />
    )
  }

  return (
    <div className="video-and-action-wrapper">
      <div
        className="uncompleted-action-container"
        key={action?.id}
        style={{
          maxWidth: '700px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            minWidth: 0,
            overflow: 'hidden',
            flexGrow: 1
          }}
        >
          {(action?.actionType !== ActionTypes.DAILY_CHECKIN || isCompletedOrReview) && (
            <Checkbox
              disabled={isCompletedOrReview}
              defaultChecked={isCompletedOrReview}
              onChange={(e) =>
                completeAction.mutate({
                  id: parseInt(e.target.value),
                  status: nextStatus,
                  organizationRoleId,
                  description: action?.description,
                  spaceId: undefined,
                  dueBy: undefined
                })
              }
              value={action?.id}
              style={{
                flexShrink: 0,
                marginRight: '8px',
                marginBottom: isVideoUrl ? '0.25rem' : '0',
                alignSelf: isVideoUrl ? 'flex-start' : 'center'
              }}
            />
          )}
          {action?.actionType !== ActionTypes.EXERCISE && (
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
              }}
            >
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {action?.description}
              </div>
              <div
                style={{
                  opacity: 0.5,
                  fontSize: '0.85rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {action?.space?.name}
              </div>
            </div>
          )}
          {action?.actionType === ActionTypes.EXERCISE && (
            <ListExercisesItem
              currentUser={currentUser}
              key={exercise?.id}
              organizationRoleId={organizationRoleId}
              exercise={exercise}
              readOnly={true}
              isAction={true}
            />
          )}
        </div>
        <div style={{ display: 'flex', marginLeft: '1rem', flexShrink: 0 }}>
          {action?.actionType === ActionTypes.DAILY_CHECKIN && !viewOnly && (
            <div
              className="button secondary"
              style={{ marginRight: '0.5rem' }}
              onClick={() =>
                getDailyCheckin({
                  description: action?.description,
                  actionId: action?.id
                })
              }
            >
              Check In
            </div>
          )}
          {action?.actionType === ActionTypes.COMMUNITY_ONBOARDING && (
            <div
              className="button secondary"
              style={{ marginRight: '0.5rem' }}
              onClick={() =>
                onboardingActions[action?.description]?.requiresNewWindow
                  ? navigateToStudio()
                  : navigate(onboardingActions[action?.description]?.route ?? '/')
              }
            >
              Complete
            </div>
          )}
          {action?.actionType === ActionTypes.TASK && !isCompletedOrReview && (
            <ActionSettingsMenu
              actionId={action?.id}
              existingAction={action}
              currentUser={currentUser}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Action
