import { add, isBefore, startOfDay } from 'date-fns'
import { useOrganizationDailyReportsQuery } from '../../../shared/queryHooks'
import { Link } from 'react-router-dom'
import { formatDateString } from '../../../shared/utilities'
import { useAuthContext } from '../../../context/AuthContext'
import { logError } from '../../../shared/logger'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import LoadingScreen from '../../../components/LoadingScreen'
import Button from '../../../components/Button'
import './index.css'
import { useState } from 'react'

function ReportsDailyOverview () {
  const { currentUser } = useAuthContext()
  const [showAllDays, setShowAllDays] = useState(false)
  const ITEMS_TO_SHOW = 5

  const { isLoading, isError, data: reports, error } = useOrganizationDailyReportsQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser })

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar title="Daily Report" />
        <LoadingScreen />
      </>
    )
  }

  if (isError) {
    logError(error)
    return (
      <SecondaryNavBar title="Daily Report" />
    )
  }

  const getDayRanges = () => {
    if (!reports || reports.length === 0) {
      return null
    }

    const startDateTimeUTC = reports[0]?.dueBy
    const startDay = startOfDay(new Date(startDateTimeUTC))
    const dayList: Date[] = []

    const addAndIncrementDay = (startDay: Date) => {
      dayList.push(startDay)
      if ((isBefore(add(startDay, { days: 1 }), new Date()))) {
        addAndIncrementDay(add(startDay, { days: 1 }))
      }
    }
    addAndIncrementDay(startDay)
    dayList.sort((a, b) => b.getTime() - a.getTime())
    return dayList
  }

  const dayRangeLinks = (() => {
    const dayRanges = getDayRanges()
    if (!dayRanges) return null

    const visibleDays = showAllDays ? dayRanges : dayRanges.slice(0, ITEMS_TO_SHOW)

    return (
      <>
        {visibleDays.map((item, index) => {
          const params = new URLSearchParams({ start: item.getTime().toString(), next: add(item, { days: 1 }).getTime().toString() })
          const formattedDayString = `${formatDateString(item)}`
          return (
            <Link
              key={index}
              to={`/communities/settings/reports/daily?${params?.toString()}`}>
              {formattedDayString}
            </Link>
          )
        })}
        {!showAllDays && dayRanges.length > ITEMS_TO_SHOW && (
          <Button
            style={{ marginTop: '1rem' }}
            onClick={() => setShowAllDays(true)}
            size="sm"
            variant="secondary"
          >
            Show All
          </Button>
        )}
      </>
    )
  })()

  return (
    <>
      {reports?.length > 0 && <div className='reports-page-container report-nav'>
        <div className='daily-history-container'>
        <h2>Daily Check-in Reports</h2>
          <div className="daily-history-item">
            {dayRangeLinks}
          </div>
        </div>
      </div>}
    </>
  )
}

export default ReportsDailyOverview
