import { Space } from '../../types'
import ManageSpaceRoles from '../../components/ManageSpaceRoles'

interface AddOrEditSpacerolesProps {
  existingSpace: Space;
  created?: boolean;
  showAllMembers?: boolean;
}

function AddOrEditSpaceroles ({
  existingSpace,
  created = false,
  showAllMembers
}: AddOrEditSpacerolesProps) {
  return (
    <div className="manage-space-roles-wrapper">
      <ManageSpaceRoles
        existingSpace={existingSpace}
        created={created}
        showAllMembers={showAllMembers}
      />
    </div>
  )
}

export default AddOrEditSpaceroles
