import { useRef } from 'react'
import { toast } from 'sonner'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useAuthContext } from '../../context/AuthContext'
import { getCurrentOrganization } from '../../shared/utilities'

function InviteLink () {
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const selectRef = useRef<HTMLInputElement>(null)
  const inviteLink = `${window.location.origin}/communities/invite/${currentOrganization?.inviteUid}`

  async function getInviteLink () {
    selectRef.current?.select()
    await navigator.clipboard.writeText(inviteLink)
  }

  if (!currentOrganization?.inviteUid) return null

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          width: '100%',
          position: 'relative'
        }}
      >
        <input
          type="text"
          readOnly
          value={inviteLink}
          className="invite-link-input"
          style={{
            flex: 1,
            cursor: 'default',
            paddingRight: '3rem'
          }}
          ref={selectRef}
          onFocus={() => {
            selectRef.current?.select()
          }}
        />
        <button
          className="button ghost"
          onClick={async () => {
            if (currentOrganization?.inviteUid) {
              await getInviteLink()
              toast.success('Invite link copied to clipboard')
            }
          }}
          style={{ position: 'absolute', right: '-0.5rem' }}
        >
          <ContentCopyIcon fontSize="small" />
        </button>
      </div>
      <p style={{ fontSize: '0.85rem', opacity: 0.6, marginTop: '0' }}>
        Copy and Share link to invite New Members to the Community
      </p>
  </>
  )
}

export default InviteLink
