import { ProfileOnboarding } from '../../components/ProfileOnboarding'
import { useEffect, useState } from 'react'
import LoadingScreen from '../../components/LoadingScreen'

function OrganizationInviteClaim () {
  const [hasCommunityIntent, setHasCommunityIntent] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const joinCommunityIntent = localStorage.getItem('joinCommunityIntent') ?? ''

  useEffect(() => {
    if (joinCommunityIntent) {
      setHasCommunityIntent(true)
    }
    setIsLoading(false)
  }, [])

  if (isLoading) {
    return <LoadingScreen />
  }

  const noCommunityIntentDisplay = (
    <div>
      <div>No invite found to join a community</div>
    </div>
  )

  const communityIntentDisplay = (
    <div>
      <ProfileOnboarding joinCommunityIntent={joinCommunityIntent} />
    </div>
  )

  return (
    <div>
      {hasCommunityIntent ? communityIntentDisplay : noCommunityIntentDisplay}
    </div>
  )
}

export default OrganizationInviteClaim
