import { useState, useRef } from 'react'
import { generateAvatarUploadUrlForUser } from '../../shared/api'
import { useAuthContext } from '../../context/AuthContext'
import { useUserMutation } from '../../shared/queryHooks'
import { useQueryClient } from '@tanstack/react-query'
import { postAvatarUrl } from '../../shared/s3'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import { ImageListType } from 'react-images-uploading'
import ProfileSettings from '../../components/ProfileSettings'
import { User } from '../../types'
import ActionsPage from '../ActionsPage'
import { useModal } from '../../context/ModalContext'
import Avatar from '../../components/Avatar'
import { toast } from 'sonner'
import Button from '../../components/Button'

function ProfileSettingsModalContent ({ currentUser }) {
  const editor = useRef<any>(null)
  const queryClient = useQueryClient()
  const editUser = useUserMutation()
  const { clearModal } = useModal()
  const [isSaving, setIsSaving] = useState(false)

  const [user, setUser] = useState({
    firstName: currentUser?.firstName ?? '',
    lastName: currentUser?.lastName ?? '',
    currentOrganizationId: currentUser?.currentOrganizationId,
    shareData: currentUser?.shareData ?? false,
    email: currentUser?.email ?? ''
  })
  const [avatar, setAvatar] = useState<ImageListType>(
    currentUser?.avatar ? [{ data_url: currentUser?.avatar }] : []
  )
  const [hasChangedAvatar, setHasChangedAvatar] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onChange = ({ attribute, value }) => {
    setUser((prev) => ({ ...prev, [attribute]: value }))
  }

  function isEmptyNameField ({ value, attribute }) {
    setUser((prev) => ({ ...prev, [attribute]: value }))

    if (value.trim() === '') {
      setErrorMessage(`Please enter a ${attribute}`)
    } else {
      setErrorMessage('')
    }
  }

  function hasRequiredAttributes () {
    if (user.firstName !== '' && user.lastName !== '') {
      return true
    }
    return false
  }

  async function submitForm () {
    setIsSaving(true)
    if (!hasRequiredAttributes()) {
      setErrorMessage('Please enter all required attributes')
      return
    }

    try {
      if (avatar.length > 0 && editor.current?.props?.image) {
        const { url, uploadUrl } = await generateAvatarUploadUrlForUser()
        if (!url || !uploadUrl) {
          throw new Error('Error generating avatar upload url')
        }
        await postAvatarUrl({ avatarUploadUrl: uploadUrl, editor })
        await editUser.mutateAsync({
          ...(user as User),
          avatar: url
        })
      } else {
        await editUser.mutateAsync({
          ...(user as User),
          avatar: currentUser?.avatar
        })
      }
      setHasChangedAvatar(false)
      await queryClient.invalidateQueries({ queryKey: ['user'] })
      toast.success('Profile updated')
      clearModal()
    } catch (error) {
      console.error(error)
      toast.error('Error updating avatar')
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div className="profile-setting-container">
      {errorMessage && <div className="edit-error">{errorMessage}</div>}
      <div
        className="profile-fields-container"
        data-testid="profile-settings-form"
      >
        <ProfileSettings
          user={user}
          avatar={avatar}
          onChange={onChange}
          setAvatar={setAvatar}
          editor={editor}
          hasChangedAvatar={hasChangedAvatar}
          setHasChangedAvatar={setHasChangedAvatar}
          setSaveMessage={(message) => {
            if (message) {
              toast.success(message)
            }
          }}
          isEmptyNameField={isEmptyNameField}
        />
      </div>
      <Button
        className="button footer-button"
        data-testid="edit-save-button"
        onClick={() => submitForm()}
        isLoading={isSaving}
      >
        Save
      </Button>
    </div>
  )
}

function ProfileSettingsForm () {
  const { currentUser, signOutUser } = useAuthContext()
  const { makeModal } = useModal()

  const handleEditProfile = () => {
    makeModal({
      modal: <ProfileSettingsModalContent currentUser={currentUser} />,
      title: 'Edit Profile'
    })
  }

  return (
    <>
      <SecondaryNavBar title="You" />
      <div className="profile-card-wrapper">
        <div className="profile-card">
          <Avatar
            className="profile-avatar"
            avatar={currentUser?.avatar}
            firstName={currentUser?.firstName}
            lastName={currentUser?.lastName}
          />
          <div className="profile-info">
            <h2>{`${currentUser?.firstName} ${currentUser?.lastName}`}</h2>
            <p>{currentUser?.email}</p>
          </div>
          <div className="profile-actions">
            <button className="button secondary" onClick={handleEditProfile}>
              Edit
            </button>
            <div
              className="button secondary"
              data-testid="profile-security-signout-button"
              onClick={signOutUser}
            >
              Sign Out
            </div>
          </div>
        </div>
        <ActionsPage />
      </div>
    </>
  )
}

export default ProfileSettingsForm
