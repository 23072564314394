import { Add, RemoveCircleOutlineRounded } from '@mui/icons-material'
import { Switch, FormControlLabel, IconButton } from '@mui/material'
import './index.css'
import { useState } from 'react'
import { useModal } from '../../context/ModalContext'
import InviteLink from '../../components/InviteLink'
import { validateEmailBoolean } from '../../shared/validators'
import { v4 as uuidv4 } from 'uuid'
import { InvitePerson } from '../../types'
import { useInvitePeopleOrCreateContactsMutation, usePeopleQuery } from '../../shared/queryHooks'
import { PuffLoader } from 'react-spinners'
import Button from '../../components/Button'
import { useAuthContext } from '../../context/AuthContext'
import { getCurrentOrganization } from '../../shared/utilities'

function InvitePeople () {
  const { clearModal } = useModal()
  const createOrgRolesAndMaybeInviteEmails =
    useInvitePeopleOrCreateContactsMutation()
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const [errorMessage, setErrorMessage] = useState('')
  const [people, setPeople] = useState<InvitePerson[]>([
    { id: uuidv4(), firstName: '', lastName: '', email: '' }
  ])
  const { data: existingPeople } = usePeopleQuery(currentUser?.currentOrganizationId ?? 0, { enabled: !!currentUser })
  const [sendEmails, setSendEmails] = useState(true)
  const [errors, setErrors] = useState<{
    [key: string]: { firstName: boolean; lastName: boolean; email: boolean };
  }>({})

  const addPerson = () => {
    setPeople([
      ...people,
      { id: uuidv4(), firstName: '', lastName: '', email: '' }
    ])
  }

  const removePerson = (id: string) => {
    setErrorMessage('')
    setPeople(people.filter((person) => person.id !== id))
  }

  const updatePerson = (
    id: string,
    field: keyof InvitePerson,
    value: string
  ) => {
    setErrorMessage('')
    setPeople(
      people.map((person) =>
        person.id === id ? { ...person, [field]: value } : person
      )
    )
    const person = people.find((person) => person.id === id)
    setErrors((prevErrors) => ({
      ...prevErrors,
      [person?.id ?? '']: {
        ...(prevErrors[person?.id ?? ''] || {}),
        [field]: false
      }
    }))
  }

  const handleSubmit = async () => {
    setErrorMessage('')

    const filteredPeople = people.filter(
      (person) =>
        person.firstName.trim() || person.lastName.trim() || person.email.trim()
    )

    if (filteredPeople.length !== people.length) {
      setPeople(filteredPeople)
    }

    const newErrors = filteredPeople.reduce(
      (acc, person) => ({
        ...acc,
        [person.id]: {
          firstName: !person.firstName.trim(),
          lastName: !person.lastName.trim(),
          email: sendEmails
            ? !person.email.trim() || !validateEmailBoolean(person.email)
            : person.email.trim()
              ? !validateEmailBoolean(person.email)
              : false
        }
      }),
      {}
    )

    const hasErrors = Object.values(
      newErrors as Record<
        string,
        { firstName: boolean; lastName: boolean; email: boolean }
      >
    ).some(
      (error): boolean => error.firstName || error.lastName || error.email
    )

    setErrors(newErrors)

    if (hasErrors) {
      const hasEmailErrors = Object.values(
        newErrors as Record<
          string,
          { firstName: boolean; lastName: boolean; email: boolean }
        >
      ).some((error) => error.email)
      setErrorMessage(
        hasEmailErrors
          ? 'Please fill out all required fields and provide valid emails'
          : 'Please fill out all required fields'
      )
      return
    }
    await createOrgRolesAndMaybeInviteEmails.mutateAsync({
      people: filteredPeople,
      sendEmails
    })
    clearModal()
  }

  const checkForExistingPerson = (person: InvitePerson) => {
    if (!existingPeople) return null

    const fullName = `${person.firstName} ${person.lastName}`.trim().toLowerCase()
    const emailMatch = existingPeople.find(
      p => p.contact?.email?.toLowerCase() === person.email.toLowerCase()
    )
    const nameMatch = existingPeople.find(
      p => `${p.contact?.firstName} ${p.contact?.lastName}`.trim().toLowerCase() === fullName && fullName !== ''
    )

    if (emailMatch && person.email) {
      return { type: 'exact', message: 'Someone with this email already exists' }
    }
    if (nameMatch && person.firstName && person.lastName) {
      return { type: 'similar', message: 'Someone with this name already exists' }
    }
    return null
  }

  return (
    <div className="invite-people-modal">
      {currentOrganization?.membersCanInvite && (
        <div className="invite-link-section">
          <div>Share public invite link</div>
          <InviteLink />
        </div>
      )}
      <div className="invite-people-section">
        {currentOrganization?.membersCanInvite && <div>Or, invite people</div>}
        {errorMessage && (
          <div className="invite-error-message">{errorMessage}</div>
        )}
        {people.map((person) => (
          <div key={person.id}>
            <div className="person-inputs">
              <input
                placeholder="First name"
                value={person.firstName}
                onChange={(e) =>
                  updatePerson(person.id, 'firstName', e.target.value)
                }
                className={`invite-input ${
                  errors[person.id]?.firstName ? 'invite-error-input' : ''
                }`}
                style={{ flex: 1 }}
              />
              <input
                placeholder="Last name"
                value={person.lastName}
                onChange={(e) =>
                  updatePerson(person.id, 'lastName', e.target.value)
                }
                className={`invite-input ${
                  errors[person.id]?.lastName ? 'invite-error-input' : ''
                }`}
                style={{ flex: 1 }}
              />
              <input
                placeholder={sendEmails ? 'Email' : 'Email (Optional)'}
                type="email"
                value={person.email}
                onChange={(e) => updatePerson(person.id, 'email', e.target.value)}
                className={`invite-input ${
                  errors[person.id]?.email ? 'invite-error-input' : ''
                }`}
                style={{ flex: 2 }}
              />
              <IconButton
                onClick={() => removePerson(person.id)}
                className="remove-button"
                disabled={people.length === 1}
              >
                <RemoveCircleOutlineRounded />
              </IconButton>
            </div>
            {checkForExistingPerson(person) && (
              <div
                style={{
                  color: checkForExistingPerson(person)?.type === 'exact' ? '#d32f2f' : '#ed6c02',
                  fontSize: '0.85rem',
                  marginTop: '4px',
                  marginLeft: '4px',
                  marginBottom: '4px'
                }}
              >
                {checkForExistingPerson(person)?.message}
              </div>
            )}
          </div>
        ))}
        <Button
          onClick={addPerson}
          className="add-person-button"
          variant="ghost"
        >
          <Add style={{ fontSize: '1.2rem' }} />
          Add person
        </Button>
      </div>
      <div className="modal-footer">
        <FormControlLabel
          control={
            <Switch
              checked={sendEmails}
              onChange={(e) => setSendEmails(e.target.checked)}
            />
          }
          label="Send email invites"
        />
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <div className="button secondary" onClick={() => clearModal()}>
            Cancel
          </div>
          <button
            type="button"
            disabled={
              people.length === 0 ||
              people.every(
                (person) =>
                  !person.firstName && !person.lastName && !person.email
              )
            }
            className={`button ${
              people.length === 0 ||
              people.every(
                (person) =>
                  !person.firstName && !person.lastName && !person.email
              )
                ? 'disabled-button'
                : ''
            }`}
            onClick={handleSubmit}
          >
            {createOrgRolesAndMaybeInviteEmails.isLoading
              ? (
              <PuffLoader color="#fff" size={20} />
                )
              : sendEmails
                ? (
                    'Invite People'
                  )
                : (
                    'Add People'
                  )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default InvitePeople
