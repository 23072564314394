import { NavLink, useNavigate } from 'react-router-dom'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import { useAuthContext } from '../../context/AuthContext'
import { getCurrentOrganization } from '../../shared/utilities'
import { config } from '../../shared/config'
import { Entity } from '../../shared/enums'
import { useRef } from 'react'
import { useEffectOnce } from '../../shared/hooks'
import { isFeatureEnabled } from '../../shared/posthog'
import { Feature } from '../../shared/features'
import './index.css'

function OrganizationSettingsNav () {
  const navigate = useNavigate()
  const myRef: any = useRef(null)

  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)

  function navigateToStudio () {
    window.open(
      `${config.STUDIO_HOST}/?organization=${currentUser?.currentOrganizationId}`,
      '_blank'
    )
  }

  const scrollTo = (e: React.BaseSyntheticEvent<MouseEvent>) => {
    if (myRef.current) {
      myRef.current.scrollTo({
        behavior: 'smooth',
        left: e.target.offsetLeft + (e.target.offsetWidth / 2) - (window.innerWidth / 2)
      })
    }
  }

  useEffectOnce(() => {
    if (myRef.current) {
      const activeElement = myRef.current.querySelector('.active')
      if (activeElement) {
        myRef.current.scrollTo({
          behavior: 'smooth',
          left: activeElement.offsetLeft + (activeElement.offsetWidth / 2) - (window.innerWidth / 2)
        })
      }
    }
  })

  return (
    <div className="nav-bar settings admin-settings">
      <div className="nav-back" onClick={() => navigate('/')}>
        <ArrowBackIosRoundedIcon /> Home
      </div>
      <div className="nav-items">
        <div className="nav-menu-items" ref={myRef}>
          <div className="nav-label">{Entity.Community}</div>
          <NavLink to="/communities/settings" end onClick={scrollTo}>
            Settings
          </NavLink>
          <NavLink to="/communities/settings/billing" onClick={scrollTo}>Billing</NavLink>
          <div className="nav-label">Administration</div>
          <NavLink to="/communities/settings/people" onClick={scrollTo}>People</NavLink>
          <NavLink to="/communities/settings/spaces" onClick={scrollTo}>Spaces</NavLink>
          <NavLink to="/communities/settings/library" onClick={scrollTo}>Library</NavLink>
          {isFeatureEnabled(Feature.Goals) && (
            <NavLink to="/communities/settings/goals" onClick={scrollTo}>Goals</NavLink>
          )}
          <div className="nav-label">Tools</div>
          <NavLink to="/communities/settings/reports" onClick={scrollTo}>Reports</NavLink>
          {currentOrganization?.studioAccess && (
            <a className="external" onClick={navigateToStudio}>
              Studio <LaunchRoundedIcon fontSize="small" color="primary" />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrganizationSettingsNav
