import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import { useOutsideAlerter } from '../../shared/utilities'
import { useRef } from 'react'
import { Event, Connection, Space } from '../../types'
import DeleteConnection from '../../modals/DeleteConnection'
import { useModal } from '../../context/ModalContext'
import StartOrScheduleConnection from '../../modals/StartOrScheduleConnection'
import './index.css'

interface EventSettingsMenuProps {
  showEventSettingsMenu?: string
  setShowEventSettingsMenu?: (eventId: string) => void
  event: Event
}

function EventSettingsMenu ({ showEventSettingsMenu, setShowEventSettingsMenu, event }: EventSettingsMenuProps) {
  const wrapperRef = useRef(null)
  const { makeModal } = useModal()
  useOutsideAlerter({
    ref: wrapperRef,
    setDisplayState: () => setShowEventSettingsMenu?.('')
  })

  const isMenuOpen = showEventSettingsMenu === event.uid

  return (
    <div style={{ position: 'relative' }}>
      <div className="event-settings-menu-icon" onClick={(e) => {
        e.stopPropagation()
        setShowEventSettingsMenu?.(isMenuOpen ? '' : event?.uid ?? '')
      }}>
        <MoreHorizIcon style={{ marginTop: '5px', cursor: 'pointer' }}/>
      </div>
      {isMenuOpen &&
        <div className="event-settings-menu" style={{ top: '1.5rem', position: 'absolute', right: '0', zIndex: 1000 }} ref={wrapperRef}>
          <Paper
            sx={{ width: 175 }}
            elevation={24}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuList dense>
              <MenuItem onClick={() => makeModal({
                modal: <StartOrScheduleConnection connection={event as Connection} space={event?.space as Space} />,
                title: 'Reschedule Event'
              })}>
                <ListItemText>Reschedule</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => makeModal({
                modal: <DeleteConnection uid={event.uid} />,
                title: 'Archive Event'
              })}>
                <ListItemText>Archive</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </div>
      }
    </div>
  )
}

export default EventSettingsMenu
