export async function postAvatarUrl ({ avatarUploadUrl, editor }) {
  const croppedImageCanvas = editor.current.getImage()

  return new Promise((resolve, reject) => {
    croppedImageCanvas.toBlob(async (blob) => {
      try {
        const url = URL.createObjectURL(blob)
        const image = await fetch(url)

        await fetch(avatarUploadUrl, {
          method: 'put',
          body: await image.blob()
        })

        URL.revokeObjectURL(url)
        resolve(undefined)
      } catch (error) {
        reject(error)
      }
    })
  })
}
