import { useState, useRef, useEffect } from 'react'
import './index.css'
import { MenuItem, Checkbox, ListItemText } from '@mui/material'
import { useOutsideAlerter } from '../../shared/utilities'
import { useSearchParams } from 'react-router-dom'
import { FilterListRounded } from '@mui/icons-material'

interface SortPeopleTagsProps {
  tags?: string[];
  handleSort?: (selectedTags: string[]) => void;
  selectedTags?: string[];
  setSelectedTags?: (selectedTags: string[]) => void;
}

function SortPeopleTags ({
  tags,
  handleSort,
  selectedTags,
  setSelectedTags
}: SortPeopleTagsProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [displayTagSortDropDown, setDisplayTagSortDropDown] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter({
    ref: wrapperRef,
    setDisplayState: setDisplayTagSortDropDown
  })

  useEffect(() => {
    const urlTag = searchParams.get('tag')
    if (urlTag) {
      setSelectedTags?.([decodeURIComponent(urlTag)])
    }
  }, [searchParams])

  const handleChange = (value) => {
    const updatedSelectedTags = selectedTags?.includes(value)
      ? selectedTags?.filter((tag) => tag !== value)
      : [...(selectedTags ?? []), value]
    setSelectedTags?.(updatedSelectedTags)
    if (handleSort !== undefined) {
      handleSort(updatedSelectedTags)
    }
  }

  const clearFilter = () => {
    searchParams.delete('tag')
    setSearchParams(searchParams)
    setSelectedTags?.([])
    if (handleSort !== undefined) {
      handleSort([])
    }
  }

  const handleConvertBulkAddTagFromUTC = (tag: string) => {
    if (tag.includes('Bulk Add')) {
      const dateTimeMatch = tag.match(/Bulk Add (\d{1,2}\/\d{1,2}\/\d{4}, \d{1,2}:\d{2} [AP]M)/)
      if (dateTimeMatch) {
        const utcDate = new Date(dateTimeMatch[1] + ' UTC')
        const localDate = utcDate.toLocaleString('en-US', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        })
        return `Bulk Add ${localDate}`
      }
    }
    return tag
  }

  return (
    <div ref={wrapperRef} style={{ position: 'relative' }}>
      <div className="tag-filter-container">
        <div
          className="button transparent"
          onClick={() => setDisplayTagSortDropDown(!displayTagSortDropDown)}
          style={{
            padding: '0.25rem 0.5rem',
            paddingRight: '0',
            paddingLeft: '1rem'
          }}
        >
          <FilterListRounded />
          Filter{' '}
          {selectedTags && selectedTags?.length > 0
            ? `(${selectedTags?.length})`
            : ''}
        </div>
        {selectedTags && selectedTags?.length > 0 && (
          <div
            className="button transparent"
            onClick={clearFilter}
            style={{ padding: '0.25rem 0.5rem' }}
          >
            Clear Filter
          </div>
        )}
      </div>
      {displayTagSortDropDown && (
        <div className="tag-dropdown">
          {tags?.map((tag) => (
            <MenuItem key={tag} value={tag} onClick={() => handleChange(tag)}>
              <Checkbox
                checked={selectedTags?.some(
                  (selectedTag) => selectedTag === tag
                )}
                value={tag}
              />
              <ListItemText primary={handleConvertBulkAddTagFromUTC(tag)} />
            </MenuItem>
          ))}
        </div>
      )}
    </div>
  )
}

export default SortPeopleTags
