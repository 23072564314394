import { useAuthContext } from '../../context/AuthContext'
import {
  useSpaceQuery,
  useEventTagsQuery,
  useCreateConnectMutation,
  useQueryClient
} from '../../shared/queryHooks'
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation
} from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import LoadingScreen from '../../components/LoadingScreen'
import SecondaryNavSpaceAvatars from '../../components/SecondaryNavSpaceAvatars'
import AddOrEditSpaceroles from '../../modals/AddOrEditSpaceroles'
import { useEffect, useState } from 'react'
import { getSpace } from '../../shared/api'
import { Connection, NavigationAction } from '../../types'
import ManageSpace from '../../modals/ManageSpace'
import ErrorPage from '../ErrorPage'
import SpaceNav from '../../components/SpaceNav'
import Connections from './Connections'
import Feed from './Feed'
import Playlist from './Playlist'
import { currentDateTime } from '../../shared/dates'
import './index.css'
import Button from '../../components/Button'

interface SpaceProps {
  inOrganizationSettings?: boolean;
}

function Space ({ inOrganizationSettings = false }: SpaceProps) {
  const [searchParams] = useSearchParams()
  const paramsCreatedSpace = searchParams.get('created')
  const paramsAction = searchParams.get('action') ?? undefined
  const secondaryNavNavigation = inOrganizationSettings
    ? '/communities/settings/spaces'
    : paramsAction
      ? '/'
      : '/spaces'
  const secondaryNavTitle = paramsAction ? 'Actions' : ''
  const navigate = useNavigate()
  const { makeModal } = useModal()
  const { id } = useParams()
  const { currentUser } = useAuthContext()
  const idParam = parseInt(id ?? '0')
  const location = useLocation()

  const featureTypes = {
    feed: 'Posts',
    events: 'Events',
    courses: 'Courses',
    media: 'Media'
  }

  const [activeSpaceFeature, setActiveSpaceFeature] = useState(
    featureTypes.feed
  )

  const createConnect = useCreateConnectMutation()
  const queryClient = useQueryClient()

  useEffect(() => {
    async function handleNewSpaceAndTime () {
      if (paramsCreatedSpace) {
        const space = await getSpace(id)
        makeModal({
          modal: (
            <AddOrEditSpaceroles
              existingSpace={space}
              created={!!paramsCreatedSpace}
            />
          ),
          title: `Space Members for ${space?.name}`,
          className: 'manage-space-modal'
        })
        navigate(`/spaces/${id}`)
      }
    }
    void handleNewSpaceAndTime()
  }, [])

  useEffect(() => {
    const fromConnectionEnd = localStorage.getItem('fromConnectionEnd')
    if (fromConnectionEnd) {
      setActiveSpaceFeature(featureTypes.events)
      localStorage.removeItem('fromConnectionEnd')
    }
  }, [location])

  const {
    isLoading,
    isError,
    data: space,
    error
  } = useSpaceQuery(idParam, { enabled: !!currentUser })
  const {
    isError: isTagsError,
    data: eventTags,
    isLoading: isEventTagsLoading,
    error: tagsError
  } = useEventTagsQuery({ enabled: !!currentUser && !!space })

  if (isLoading || isEventTagsLoading) {
    return <LoadingScreen />
  }

  if (isError || isTagsError) {
    const e: any = error || tagsError
    const message =
      e?.request?.status === 404
        ? '404, this page does not exist'
        : 'Something went wrong'
    return (
      <>
        <SecondaryNavBar backTo="/" title="Space" />
        <ErrorPage
          message={message}
          action={() => navigate('/')}
          actionTitle="Return home?"
        />
      </>
    )
  }

  if (!space) {
    return (
      <ErrorPage
        message="404, this page does not exist"
        action={() => navigate('/')}
        actionTitle="Return home?"
      />
    )
  }

  const liveEvents = space?.connections
    .filter(
      (connection) =>
        connection.endTime === null &&
        new Date(connection.startTime || '') < new Date()
    )
    ?.sort(
      (a, b) =>
        new Date(b.startTime || '').getTime() -
        new Date(a.startTime || '').getTime()
    )
    ?.map((connection) => connection.uid)
    ?.filter((uid): uid is string => uid !== null)

  const openManageSpaceModal = () => {
    makeModal({
      modal: (
        <ManageSpace
          space={space}
          featureTypes={featureTypes}
          setActiveSpaceFeature={setActiveSpaceFeature}
        />
      ),
      title: 'Space',
      className: 'manage-space-modal',
      disableFocus: true
    })
  }

  const getSubnavAction = () => {
    const latestLiveEvent = liveEvents?.[0]

    const actions: NavigationAction[] = []

    if (latestLiveEvent) {
      actions.push({
        action: () => navigate(`/connections/${latestLiveEvent}`),
        actionTitle: (
          <>
            <span>Join</span>
            <span className="hide-on-mobile" style={{ marginLeft: '0.2rem' }}>
              Connect
            </span>
          </>
        )
      })
    } else {
      actions.push({
        action: async () => {
          const quickConnection = {
            location: space?.location || 'Live Connect',
            startTime: new Date(currentDateTime()),
            title: 'Connect',
            connectionTags: [],
            connectionType: 'SECRET',
            spaceId: space?.id,
            postEvent: false // TODO: bring this back when we can automatically clean up old events
          }

          const newConnection = (await createConnect.mutateAsync(
            quickConnection
          )) as Connection
          await queryClient.invalidateQueries(['spaces'])
          navigate(`/connections/${newConnection.uid}`)
        },
        actionTitle: 'Connect',
        actionClassName: 'secondary'
      })
    }

    actions.push({
      action: openManageSpaceModal,
      actionTitle: <SecondaryNavSpaceAvatars spaceRoles={space?.spaceRoles} />,
      actionClassName: 'secondary icon avatar-button'
    })

    return actions
  }

  const renderActions = (actions: NavigationAction[]) => {
    return actions.map((action, index) => (
      <Button
        key={index}
        className={`${action.actionClassName} subnav-button`}
        onClick={action.action}
      >
        {action.actionTitle}
      </Button>
    ))
  }

  return (
    <div className="space-container">
      <SecondaryNavBar
        backTo={secondaryNavNavigation}
        title={secondaryNavTitle}
        pageTitle={
          <div className="space-name-container">
            <div
              className="space-name"
              onClick={() => {
                if (window.innerWidth <= 750) {
                  openManageSpaceModal()
                }
              }}
            >
              {space?.name}
              <div className="space-member-count">
                {space?.spaceRoles?.length} members
              </div>
            </div>
            <div className="space-actions">
              {renderActions(getSubnavAction())}
            </div>
          </div>
        }
      />
      <SpaceNav
        space={space}
        activeSpaceFeature={activeSpaceFeature}
        setActiveSpaceFeature={setActiveSpaceFeature}
        featureTypes={featureTypes}
        liveEvents={liveEvents}
      />
      <div className="space-page-container">
        {activeSpaceFeature === featureTypes.feed && (
          <Feed spaceId={space.id} />
        )}
        {activeSpaceFeature === featureTypes.events && (
          <Connections
            inOrganizationSettings={inOrganizationSettings}
            space={space}
            tags={eventTags}
          />
        )}
        {activeSpaceFeature === featureTypes.courses && (
          <Playlist spaceId={space.id} type="courses" />
        )}
        {activeSpaceFeature === featureTypes.media && (
          <Playlist spaceId={space.id} type="media" />
        )}
      </div>
    </div>
  )
}

export default Space
