import { Event as EventType } from '../../types/DataTypes'
import {
  formatConnectionDate,
  formatConnectionTime,
  getLocationInfo
} from '../../shared/utilities'
import { useAuthContext } from '../../context/AuthContext'
import { isAuthorized } from '../../shared/permissions'
import { getSpaceLeaderPolicy } from '../../shared/policies'
import GridViewRounded from '@mui/icons-material/GridViewRounded'
import Button from '../Button'
import { useNavigate } from 'react-router-dom'
import { useJoinEventMutation } from '../../shared/queryHooks'
import EventSettingsMenu from '../EventSettingsMenu'
import { currentDateTime } from '../../shared/dates'
interface EventProps {
  event: EventType | null;
  isActive?: boolean;
  showSettings: boolean;
  showSpaceBadge: boolean;
  onEventClick: (event: EventType) => void;
  showEventSettingsMenu?: string;
  setShowEventSettingsMenu?: (eventUid: string) => void;
}

const isEventActive = (event: EventType) => {
  const now = currentDateTime()
  const startTime = new Date(event.startTime).getTime()
  const endTime = event.endTime ? new Date(event.endTime).getTime() : null
  return startTime <= now && (!endTime || endTime > now)
}

function Event ({
  event,
  isActive: isActiveOverride,
  showSettings,
  showSpaceBadge,
  showEventSettingsMenu,
  setShowEventSettingsMenu
}: EventProps) {
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()
  const joinEvent = useJoinEventMutation()

  async function handleJoin () {
    await joinEvent.mutateAsync({ eventUid: event?.uid })
    navigate(`/connections/${event?.uid}`)
  }

  const hasTitle = (event: EventType) => {
    return event.title && event.title.trim() !== ''
  }

  const locationInfo = getLocationInfo(
    event?.location || event?.space?.location || ''
  )

  const isHostAndAbove = isAuthorized(
    getSpaceLeaderPolicy(event?.spaceId, currentUser?.currentOrganizationId),
    currentUser
  )

  const handleSpaceClick = () => {
    if (!location.pathname.includes('spaces')) {
      navigate(`/spaces/${event?.spaceId}`)
    }
  }

  if (!event) return null

  const isActive = isActiveOverride ?? isEventActive(event)

  return (
    <div className="event-item">
      <div style={{ width: '100%' }}>
        <div
          className="event-meta"
          style={{
            gap: '0.25rem',
            fontSize: '0.85rem',
            marginBottom: '0.5rem'
          }}
        >
          <div className="event-meta-item">
            {formatConnectionDate(event.startTime)}
          </div>
          <div className="meta-separator">•</div>
          <div className="event-meta-item">
            {formatConnectionTime(event.startTime)}
          </div>
        </div>
        <div className="event-info">
          {isActive && <div className="connection-live">Live</div>}
          <div className="event-title">
            {hasTitle(event) ? event.title : 'Event'}
          </div>
          <div className="event-buttons">
            {isActive
              ? (
              <Button onClick={handleJoin}>Join</Button>
                )
              : (
              <Button className="secondary" onClick={handleJoin}>
                View
              </Button>
                )}
            {isHostAndAbove && showSettings && (
              <EventSettingsMenu
                event={event}
                showEventSettingsMenu={showEventSettingsMenu}
                setShowEventSettingsMenu={setShowEventSettingsMenu}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            overflow: 'hidden',
            width: '100%'
          }}
        >
          <div className="event-location">
            <div>{locationInfo.icon}</div>
            <div className="location-text">{locationInfo.text}</div>
          </div>
          {showSpaceBadge && (
            <div className="event-space" onClick={handleSpaceClick}>
              <GridViewRounded
                fontSize="small"
                style={{ fontSize: '0.8rem', marginRight: '0.25rem' }}
              />
              {event.space?.name}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Event
