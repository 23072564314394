import { useState } from 'react'
import ManageUserSpaceRoles from '../ManageUserSpaceRoles'
import { DisplayState } from '../../shared/utilities'
import './index.css'
import { OrganizationRole, Space, SpaceRole } from '../../types'

interface PersonItemProps {
  person: OrganizationRole
  space: Space
  setSpace: DisplayState<Space>
}

function PersonItem ({ person, space, setSpace }: PersonItemProps) {
  const currentSpaceRole = space?.spaceRoles?.find((spaceRole) => spaceRole?.organizationRoleId === person?.id)
  const defaultSpaceRole: SpaceRole = {
    id: 0,
    spaceId: space?.id ?? 0,
    organizationRole: { ...person, contactId: person?.contact?.id ?? 0, title: 'MEMBER' },
    organizationRoleId: person?.id,
    title: 'MEMBER'
  }

  const [checked, setChecked] = useState<boolean>(currentSpaceRole != null)
  const [spaceRole, setSpaceRole] = useState(currentSpaceRole || defaultSpaceRole)

  const togglePerson = (organizationRoleId) => {
    if (checked) {
      const newList = space?.spaceRoles?.filter((spaceRole) => spaceRole?.organizationRoleId !== organizationRoleId)
      setSpace((prev) => ({ ...prev, spaceRoles: newList }))
    } else {
      setSpace((prev) => ({ ...prev, spaceRoles: [...(prev?.spaceRoles ?? []), { ...spaceRole }] }))
    }
    setChecked((prev) => !prev)
  }

  const rightColumnContent = () => {
    if (checked) {
      return <ManageUserSpaceRoles selectRole={selectRole} spaceRole={spaceRole} person={person} />
    } else {
      return <></>
    }
  }

  function selectRole (title) {
    setSpaceRole((prev) => ({ ...prev, title }))
    setSpace((prev) => ({
      ...prev,
      spaceRoles: prev?.spaceRoles?.map((spaceRole) =>
        spaceRole?.organizationRoleId === person?.id ? { ...spaceRole, title } : spaceRole
      )
    }))
  }

  return (
    <div>
      <div className="person-card-container">
        <div className="inner-person-card">
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              value={person?.id}
              checked={checked}
              onChange={(e) => togglePerson(parseInt(e.target.value))}
              type="checkbox"
              className='person-checkbox'
            />
            <div className="person-name">
              {person?.contact?.firstName} {person?.contact?.lastName}
            </div>
          </label>
        </div>
        {rightColumnContent()}
      </div>
    </div>
  )
}

export default PersonItem
