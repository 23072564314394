import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import { useRef } from 'react'
import './index.css'
import { ExpandMoreRounded } from '@mui/icons-material'

function EditConnectionMenu ({
  editConnect,
  setEditConnect,
  useSharedOutsideAlerter,
  hasAttendance,
  hasCourse,
  swapPinned,
  setEditAttendance,
  setEditCourse,
  setEditFreedomChecks,
  hasFreedomChecksEnabled
}) {
  const editConnectionRef = useRef(null)
  useSharedOutsideAlerter({ editConnectionRef })

  const addons = {
    attendance: 'attendance',
    course: 'course',
    freedomChecks: 'freedomChecks'
  }

  function editConnection (addon) {
    if (addon === addons.attendance) {
      setEditAttendance(true)
    }

    if (addon === addons.course) {
      setEditCourse(true)
    }

    if (addon === addons.freedomChecks) {
      setEditFreedomChecks(true)
    }
    swapPinned(addon)
    setEditConnect(false)
  }

  return (
    <div className="edit-connect-dropdown-container" ref={editConnectionRef}>
      <div
        className="connection-status"
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        onClick={() => setEditConnect(!editConnect)}
      >
        View Only <ExpandMoreRounded />
      </div>
      {editConnect && (
        <div className="edit-connect-dropdown">
          <Paper sx={{ width: 175 }} elevation={24}>
            <MenuList dense>
              {hasCourse && (
                <MenuItem onClick={() => editConnection(addons.course)}>
                  <ListItemText>Edit Course Progress</ListItemText>
                </MenuItem>
              )}
              {hasAttendance && (
                <MenuItem onClick={() => editConnection(addons.attendance)}>
                  <ListItemText>Edit Attendance</ListItemText>
                </MenuItem>
              )}
              {hasAttendance && hasFreedomChecksEnabled && (
                <MenuItem onClick={() => editConnection(addons.freedomChecks)}>
                  <ListItemText>Edit Freedom Checks</ListItemText>
                </MenuItem>
              )}
            </MenuList>
          </Paper>
        </div>
      )}
    </div>
  )
}

export default EditConnectionMenu
