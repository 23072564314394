import { useModal } from '../../context/ModalContext'
import { useJoinEventMutation } from '../../shared/queryHooks'
import { useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { formatConnectionDate, formatConnectionTime, getLocationInfo } from '../../shared/utilities'
import GridViewRounded from '@mui/icons-material/GridViewRounded'

function JoinEvent ({ event }) {
  const { clearModal } = useModal()
  const navigate = useNavigate()
  const joinEvent = useJoinEventMutation()
  const locationInfo = getLocationInfo(
    event?.location || event?.space?.location || ''
  )

  const getEventStatus = () => {
    if (event.endTime) return 'over'
    if (new Date(event.startTime) > new Date()) return 'scheduled'
    return 'live'
  }

  const getButtonText = () => {
    const status = getEventStatus()
    return status === 'live' ? 'Join' : 'View'
  }

  const isSecondaryButton = () => {
    return getEventStatus() !== 'live'
  }

  async function handleSubmit () {
    await joinEvent.mutateAsync({ eventUid: event.uid })
    navigate(`/connections/${event.uid}`)
    clearModal()
  }

  return (
    <div>
      <div style={{ marginBottom: '0.5rem' }}>Would you like to join {(event.title === null || event.title === '') && 'this'} event {event.title === null || event.title === '' ? '' : `"${event.title}"`} on {formatConnectionDate(event.startTime)} at {formatConnectionTime(event.startTime)}</div>
      <div style={{ fontStyle: 'italic' }}>This event is {' '}
        {getEventStatus() === 'over'
          ? 'over'
          : getEventStatus() === 'scheduled'
            ? 'scheduled for later'
            : 'currently live'}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between', marginTop: '2rem' }}>
        <div className="event-location">
          <div>{locationInfo.icon}</div>
          <div>{locationInfo.text}</div>
        </div>
        <div className="event-space" style={{ cursor: 'default' }}>
          <GridViewRounded
            fontSize="small"
            style={{ fontSize: '0.8rem', marginRight: '0.25rem' }}
          />
          {event.space?.name}
        </div>
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>
          Cancel
        </div>
        <div
          className={`button ${isSecondaryButton() ? 'secondary' : ''}`}
          onClick={() => handleSubmit()}
        >
          {joinEvent.isLoading
            ? (
              <PuffLoader color="#fff" size={21} />
              )
            : getButtonText()}
        </div>
      </div>
    </div>
  )
}

export default JoinEvent
