import { useCallback, useEffect, useState } from 'react'
import { useAuthContext } from '../../context/AuthContext'
import SelectPermission from '../../components/SelectPermission'
import {
  useAddPeopleMutation,
  useEditPersonMutation
} from '../../shared/queryHooks'
import { useModal } from '../../context/ModalContext'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import {
  formatPhoneNumberInput,
  getCurrentOrganization
} from '../../shared/utilities'
import { PuffLoader } from 'react-spinners'
import { logError } from '../../shared/logger'
import {
  validateEmail,
  validateRoleRequirements,
  validateFirstName,
  validateLastName
} from '../../shared/validators'
import GoogleAddressSearch from '../../components/GoogleAddressSearch'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import { Entity, roles } from '../../shared/enums'
import { OrganizationRole, Contact } from '../../types'
import { parsedErrorMessage } from '../../shared/errors'
import { formattedDate, parseDate } from '../../shared/dates'
import { useNavigate } from 'react-router-dom'
import InviteLinkSection from '../../components/InviteLinkSection'

interface AddOrEditPersonProps {
  existingPerson?: OrganizationRole;
  spaceId?: number;
  memberInviteOnly?: boolean;
}

function AddOrEditPerson ({
  existingPerson,
  spaceId,
  memberInviteOnly
}: AddOrEditPersonProps) {
  const navigate = useNavigate()
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const { clearModal } = useModal()
  const focusRef = useCallback((node) => {
    if (node !== null) {
      node.focus()
    }
  }, [])

  const {
    user: prevUser,
    contact: prevContact,
    title: prevTitle,
    organizationId: prevOrganizationId,
    spaceRoles: prevSpaceRoles
  } = existingPerson || {}

  const contact: Contact = {
    firstName: prevContact?.firstName || '',
    lastName: prevContact?.lastName || '',
    dob: prevContact?.dob ? new Date(prevContact?.dob).toISOString() : null,
    address: prevContact?.address || '',
    phone: prevContact?.phone || '',
    id: prevContact?.id || 0,
    email: prevContact?.email || prevUser?.email || '',
    shareData: prevContact?.shareData || false,
    createdAt: prevContact?.createdAt || null
  }

  const initialState = {
    contact,
    title: prevTitle || roles.member,
    organizationId: prevOrganizationId || currentUser?.currentOrganizationId,
    spaceRoles: prevSpaceRoles?.length ? prevSpaceRoles : []
  } as OrganizationRole

  const [person, setPerson] = useState<OrganizationRole>({
    ...existingPerson,
    ...initialState
  })

  const [submitted, setSubmitted] = useState<boolean>(false)

  const { email } = person?.contact || {}
  const { firstName, lastName, phone, dob, address } = person?.contact || {}
  const { title } = person || {}
  const [dateInput, setDateInput] = useState<string>(formattedDate(dob))

  useEffect(() => {
    if (submitted) {
      isFormValid()
    }
  }, [firstName, lastName, email, submitted, title, dob])

  const [error, setError] = useState<string | null>(null)

  function updatePerson (obj) {
    setPerson((prevState) => ({
      ...prevState,
      ...obj
    }))
  }

  function updateContact (obj) {
    setPerson((prevState) => ({
      ...prevState,
      contact: {
        ...prevState?.contact,
        ...obj
      }
    }))
  }

  const setDob = (date: string) => {
    const parsedDate = parseDate(date)
    const parsedDateOffsetForDST = parsedDate?.setHours(
      parsedDate?.getHours() + 12
    )
    updateContact({ dob: parsedDateOffsetForDST })
  }

  const addPeople = useAddPeopleMutation()
  const editPerson = useEditPersonMutation()

  async function callMutation () {
    try {
      if (existingPerson) {
        await editPerson.mutateAsync(person)
        clearModal()
      } else {
        const response = await addPeople.mutateAsync({
          person,
          emails: [email]
        })
        if (!spaceId) {
          navigate(`/communities/settings/people/${response?.[0]?.id}`)
        } else {
          clearModal()
        }
      }
    } catch (error) {
      logError(error)
      setError(parsedErrorMessage(error))
    }
  }

  function isFormValid () {
    try {
      validateFirstName(firstName)
      validateLastName(lastName)
      if (email !== '') {
        validateEmail(email)
        validateRoleRequirements({
          title,
          email
        })
      }
      setError(null)
      return true
    } catch (error) {
      setError(parsedErrorMessage(error))
      return false
    }
  }

  async function handleSubmit () {
    setSubmitted(true)
    isFormValid() && (await callMutation())
  }

  const submitText = existingPerson
    ? 'Save'
    : email === ''
      ? 'Add Person'
      : 'Invite Person'

  const isAdminOrAbove = isAuthorized(
    getOrganizationAdminPolicy(currentUser?.currentOrganizationId),
    currentUser
  )

  function handleEmailChange (e) {
    const input = e.target.value
    updateContact({ email: input })
  }

  if (memberInviteOnly && currentOrganization?.inviteUid) {
    return <InviteLinkSection />
  }

  return (
    <>
      {error && error.length > 0 && (
        <div
          className="message-banner"
          tabIndex={0}
          ref={focusRef}
          data-testid="invite-inline-error-message"
        >
          {error}
        </div>
      )}
      <label>
        Email
        <input
          data-testid="email-input"
          className="email-input"
          placeholder="Email"
          type="text"
          value={person.contact?.email || ''}
          onChange={handleEmailChange}
        />
      </label>
      <div className={'name-inputs'}>
        <label>
          First name
          <input
            data-testid="first-name-input"
            type="text"
            name="firstName"
            className="first-name-input"
            value={firstName}
            onChange={(e) => {
              updateContact({ [e.target.name]: e.target.value })
            }}
            placeholder="First Name"
          />
        </label>
        <label>
          Last name
          <input
            data-testid="last-name-input"
            type="text"
            name="lastName"
            className="last-name-input"
            value={lastName}
            onChange={(e) => {
              updateContact({ [e.target.name]: e.target.value })
            }}
            placeholder="Last Name"
          />
        </label>
      </div>
      <div>
        <label>
          Phone
          <input
            type="tel"
          name="phone"
          data-testid="phone-input"
          className="phone-field"
          value={phone && formatPhoneNumberInput(phone?.slice(2))}
          onChange={(e) =>
            updateContact({
              [e.target.name]:
                e.target.value && `+1${formatPhoneNumberInput(e.target.value)}`
            })
          }
            placeholder="Phone"
          />
        </label>
        <label>
          Birthday
          <input
            type="date"
          name="dob"
          data-testid="dob-input"
          className="date-field"
          value={dateInput}
          onBlur={() => setDob(dateInput)}
          onChange={(e) => setDateInput(e.target.value)}
            placeholder="Birthday"
          />
        </label>
        <label>
          Address
          <GoogleAddressSearch
            address={address}
          onChange={(e) => updateContact({ [e.target.name]: e.target.value })}
          setAddress={(address) => updateContact({ address })}
          placeholder="Address"
            limitRequestType="address"
          />
        </label>
      </div>
      {(isAdminOrAbove ||
        spaceId ||
        (currentOrganization?.defaultSpace &&
          currentOrganization?.membersCanInvite)) && (
        <>
          <div className="permission-subtitle">Role in {Entity.Community}</div>
          <SelectPermission
            person={person}
            updatePerson={updatePerson}
            prevTitle={prevTitle}
            spaceId={spaceId}
          />
        </>
      )}
      <div className="modal-footer right-align-buttons">
        <div className="buttons-container">
          <button
            className="cancel-button button secondary"
            onClick={clearModal}
          >
            Cancel
          </button>
          <button
            className="button permission-button"
            data-testid="permission-button"
            onClick={handleSubmit}
          >
            {editPerson.isLoading || addPeople.isLoading
              ? (
              <PuffLoader color="#fff" size={21} />
                )
              : (
                  submitText
                )}
          </button>
        </div>
      </div>
    </>
  )
}

export default AddOrEditPerson
