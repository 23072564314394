import { useState } from 'react'
import { useModal } from '../../context/ModalContext'
import { validateEmailBoolean } from '../../shared/validators'
import {
  Contact,
  InvitePerson as InvitePersonType,
  OrganizationRole
} from '../../types'
import {
  useEditPersonMutation,
  useSendInvitationMutation
} from '../../shared/queryHooks'
import { PuffLoader } from 'react-spinners'

export function InvitePerson ({
  existingPerson
}: {
  existingPerson: OrganizationRole;
}) {
  const { clearModal } = useModal()
  const sendInvitation = useSendInvitationMutation()
  const editPerson = useEditPersonMutation()

  const [errorMessage, setErrorMessage] = useState('')
  const [person, setPerson] = useState<Omit<InvitePersonType, 'id'>>({
    firstName: existingPerson.contact?.firstName || '',
    lastName: existingPerson.contact?.lastName || '',
    email: existingPerson.contact?.email || ''
  })
  const [errors, setErrors] = useState<{
    firstName: boolean;
    lastName: boolean;
    email: boolean;
  }>({
    firstName: false,
    lastName: false,
    email: false
  })

  const updatePerson = (field: keyof InvitePersonType, value: string) => {
    setErrorMessage('')
    setPerson({ ...person, [field]: value })
    setErrors({ ...errors, [field]: false })
  }

  const handleSubmit = async () => {
    setErrorMessage('')

    const newErrors = {
      firstName: !person.firstName.trim(),
      lastName: !person.lastName.trim(),
      email: !person.email.trim() || !validateEmailBoolean(person.email)
    }

    const hasErrors = Object.values(newErrors).some((error) => error)

    setErrors(newErrors)

    if (hasErrors) {
      setErrorMessage(
        newErrors.email
          ? 'Please fill out all required fields and provide valid email'
          : 'Please fill out all required fields'
      )
      return
    }

    const nextPerson = {
      ...existingPerson,
      contact: {
        ...existingPerson.contact,
        firstName: person.firstName,
        lastName: person.lastName,
        email: person.email
      } as Contact
    }

    await editPerson.mutateAsync(nextPerson)
    await sendInvitation.mutateAsync(nextPerson)
    clearModal()
  }

  return (
    <div className="invite-people-modal">
      <div className="invite-people-section">
        {errorMessage && (
          <div className="invite-error-message">{errorMessage}</div>
        )}
        <div
          style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}
        >
          <label>
            Email
            <input
              placeholder="Email address"
              type="email"
              value={person.email}
              onChange={(e) => updatePerson('email', e.target.value)}
              className={`${errors.email ? 'invite-error-input' : ''}`}
              autoFocus
            />
          </label>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <label>
              First name
              <input
                placeholder="First name"
                value={person.firstName}
                onChange={(e) => updatePerson('firstName', e.target.value)}
                className={`${errors.firstName ? 'invite-error-input' : ''}`}
              />
            </label>
            <label>
              Last name
              <input
                placeholder="Last name"
                value={person.lastName}
                onChange={(e) => updatePerson('lastName', e.target.value)}
                className={`${errors.lastName ? 'invite-error-input' : ''}`}
              />
            </label>
          </div>
        </div>
      </div>
      <div
        className="modal-footer"
        style={{ gap: '1rem', justifyContent: 'flex-end', marginTop: '0.5rem' }}
      >
        <div className="button secondary" onClick={() => clearModal()}>
          Cancel
        </div>
        <div className="button" onClick={handleSubmit}>
          {sendInvitation.isLoading || editPerson.isLoading
            ? (
            <PuffLoader color="#fff" size={20} />
              )
            : (
                'Send Invite'
              )}
        </div>
      </div>
    </div>
  )
}
