import { sortByCompletedAction } from '../../shared/utilities'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './index.css'
import { Action as ActionType, Exercise } from '../../types'
import Action from '../Action'

interface CompletedActionsProps {
  actions?: ActionType[];
  organizationRoleId?: number;
  actionExercises?: Exercise[];
  tags?: any[];
}

function CompletedActions ({
  actions,
  organizationRoleId,
  actionExercises,
  tags
}: CompletedActionsProps) {
  const completedActions = actions
    ?.filter(
      (action) => action.status === 'COMPLETED' || action.status === 'REVIEW'
    )
    .sort(sortByCompletedAction)

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Completed
            <div style={{ opacity: 0.5 }}>
              {completedActions?.length ?? 0} actions
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {completedActions?.map((action) => (
            <Action
              key={action?.id}
              action={action}
              organizationRoleId={organizationRoleId}
              viewOnly={true}
              actionExercises={actionExercises}
              tags={tags}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default CompletedActions
