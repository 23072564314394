import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useModal } from '../../context/ModalContext'
import { useEndConnectionMutation } from '../../shared/queryHooks'
import './index.css'

function ConnectionEnd ({ connection, isSpaceLeaderOrAbove }) {
  const { clearModal } = useModal()
  const navigate = useNavigate()
  const endConnection = useEndConnectionMutation()
  const leaveButton = React.createRef<any>()

  function handleLeave () {
    localStorage.setItem('fromConnectionEnd', 'true')
    navigate(-1)
    clearModal()
  }

  async function handleArchive () {
    await endConnection.mutateAsync(connection?.uid)
    localStorage.setItem('fromConnectionEnd', 'true')
    navigate(-1)
    clearModal()
  }

  useEffect(() => {
    leaveButton.current.focus()
  }, [])

  const CanArchive = () => isSpaceLeaderOrAbove && !connection?.endTime && new Date(connection?.startTime) < new Date()

  const ArchiveContent = (
    <div>
      Inactive Connects will automatically end after 15 minutes, or you can{' '}
      <span tabIndex={0} onClick={handleArchive} className="end-connect-primary-archive-link">
        end this Connect immediately
      </span>
      . Ending a Connect will disconnect any active participants.
    </div>
  )

  const DialogButtonsContent = (
    <>
      <div className="button secondary" onClick={clearModal} tabIndex={0}>
        Rejoin
      </div>
      <div ref={leaveButton} className="button" onClick={() => handleLeave()} tabIndex={0}>
        Leave Connect
      </div>
    </>
  )

  const connectionEndActionDisplay = (
    <>
      <div className="end-connect-primary-action-container">{DialogButtonsContent}</div>
      {CanArchive() && <div className="end-connect-primary-archive-container">{ArchiveContent}</div>}
    </>
  )

  return <div className="end-connection-modal-content">{connectionEndActionDisplay}</div>
}

export default ConnectionEnd
