import { format } from 'date-fns'
import Button from '../../../components/Button'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import { useAuthContext } from '../../../context/AuthContext'
import { useOrganizationSubscriptionQuery } from '../../../shared/queryHooks'
import { useModal } from '../../../context/ModalContext'
import CreditCardIcon from '@mui/icons-material/CreditCardRounded'
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import currency from 'currency.js'
import { Skeleton, Alert } from '@mui/material'
import './index.css'
import { openSupportChat } from '../../../shared/utilities'
import ShieldRoundedIcon from '@mui/icons-material/ShieldRounded'
import ApplyPromoCode from '../../../modals/ApplyPromoCode'
import { Entity } from '../../../shared/enums'
import { StripeSubscription } from '../../../types/DataTypes'

function OrganizationSettingsBilling () {
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const { isLoading, data: subscription } = useOrganizationSubscriptionQuery(
    currentUser?.currentOrganizationId ?? 0
  )

  function formatBillingDate (date) {
    if (new Date().getFullYear() === new Date(date).getFullYear()) {
      return format(new Date(date), 'LLLL d')
    }
    return format(new Date(date), 'LLLL d, yyyy')
  }

  function formatAmountAsDollars (amountInCents) {
    return currency(amountInCents, { fromCents: true }).format()
  }

  const applyPromoCode = () => {
    makeModal({
      modal: <ApplyPromoCode currentUser={currentUser} />,
      title: 'Apply promotion'
    })
  }

  const subscriptionButtonText = subscription?.cardLast4
    ? 'Edit Subscription'
    : 'Add Payment Method'

  function getSubscriptionStates (subscription: StripeSubscription) {
    return {
      canceled: {
        title: 'Canceled',
        description: `${Entity.Community} frozen, contact us to restart your subscription and unfreeze your ${Entity.Community}`
      },
      active: {
        title: `${formatAmountAsDollars(subscription.amount)} per ${
          subscription.interval ?? 'month'
        }`,
        description: 'Current plan'
      },
      trialing: {
        title: `${formatAmountAsDollars(subscription.amount)} per ${
          subscription.interval ?? 'month'
        }`,
        description: 'Plan after trial ends'
      },
      past_due: {
        title: `${formatAmountAsDollars(subscription.amount)} per ${
          subscription.interval ?? 'month'
        }`,
        description: 'Current plan past due'
      }
    }
  }

  return (
    <>
      <SecondaryNavBar title="Billing" />
      <div className="billing-container-wrapper org-nav-settings">
        {subscription?.status === 'past_due' && !subscription.creditBalance && (
          <Alert severity="error">
            Fix payment method or add credits to avoid cancellation
          </Alert>
        )}
        <div className="billing-container">
          {subscription && (
            <>
              <div className="subscription-container">
                {getSubscriptionStates(subscription)[subscription?.status ?? ''] && (
                  <>
                    <div className="billing-amount">
                      {
                        getSubscriptionStates(subscription)[subscription?.status ?? '']
                          .title
                      }
                    </div>
                    <div className="billing-next-attempt-at">
                      {
                        getSubscriptionStates(subscription)[subscription?.status ?? '']
                          .description
                      }
                    </div>
                    <hr />
                  </>
                )}
                <div className="billing-details-container">
                  <div className="billing-details-item">
                    <div className="billing-details-item-title">
                      Credit Card
                    </div>
                    <div className="billing-details-item-value">
                      {subscription.cardLast4
                        ? (
                        <>
                          <CreditCardIcon /> •••• {subscription.cardLast4}
                        </>
                          )
                        : (
                        <>
                          <WarningRoundedIcon /> None
                        </>
                          )}
                    </div>
                  </div>
                  <div className="billing-details-item">
                    <div className="billing-details-item-title">Plan</div>
                    <div className="billing-details-item-value">
                      {subscription?.name}
                    </div>
                  </div>
                </div>
                {subscription.nextPaymentAttemptAt && (
                  <>
                    <hr />
                    {subscription.nextAmountDue !== subscription.amount && (
                      <div
                        className="billing-details-item-value"
                        style={{ marginBottom: '0.5rem' }}
                      >
                        {formatAmountAsDollars(subscription.nextAmountDue)}
                      </div>
                    )}
                    <div className="billing-next-attempt-at">
                      {subscription.status === 'trialing'
                        ? 'Payment due after your free trial ends'
                        : 'Payment due'}{' '}
                      on{' '}
                      {subscription.status === 'trialing'
                        ? formatBillingDate(subscription.periodEndAt)
                        : formatBillingDate(subscription.periodEndAt)}
                    </div>
                  </>
                )}
                {subscription.cancelAtPeriodEnd && (
                  <>
                    <hr />
                    <div className="billing-next-attempt-at">
                      <WarningRoundedIcon /> Your {Entity.Community} will be
                      deactivated on{' '}
                      {formatBillingDate(subscription.periodEndAt)}
                    </div>
                  </>
                )}
                {subscription.status === 'canceled' && (
                  <Button onClick={openSupportChat}>Contact Support</Button>
                )}
                {subscription.status !== 'canceled' && (
                  <Button href={subscription.portalUrl}>
                    {subscriptionButtonText} <ArrowOutwardRoundedIcon />
                  </Button>
                )}
                <div className="stripe-partnership">
                  <ShieldRoundedIcon /> Securely processed using Stripe
                </div>
              </div>
              <div className="account-credit-container">
                <div className="billing-amount">
                  {formatAmountAsDollars(subscription.creditBalance)} credits
                </div>
                <div className="billing-next-attempt-at">
                  Account credit balance
                </div>
                <Button className="secondary" onClick={applyPromoCode}>
                  Apply Credit
                </Button>
              </div>
            </>
          )}
          {isLoading && (
            <>
              <div className="subscription-container loading">
                <Skeleton width="50%" height={40} />
                <Skeleton width="100%" height={30} />
                <hr style={{ marginTop: '0.83rem' }} />
                <Skeleton variant="rounded" width="75%" height={50} />
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={42}
                  style={{ marginTop: '1.25rem', borderRadius: '10px' }}
                />
              </div>
              <div className="account-credit-container loading">
                <Skeleton width="50%" height={40} />
                <Skeleton width="100%" height={30} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default OrganizationSettingsBilling
