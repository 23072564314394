import {
  useCreateConnectMutation,
  useQueryClient,
  useUpdateConnectionMutation
} from '../../shared/queryHooks'
import { useState, useMemo } from 'react'
import { useModal } from '../../context/ModalContext'
import { Connection, Space } from '../../types'
import { parseDefaultedDate } from '../../shared/dates'
import { Entity } from '../../shared/enums'
import Switch from '@mui/material/Switch'
import GoogleAddressSearch from '../../components/GoogleAddressSearch'
import './index.css'
import { fromZonedTime } from 'date-fns-tz'

interface StartOrScheduleConnectionProps {
  space?: Space | null;
  connection?: Connection;
  tags?: any[];
  spaces?: Space[];
}

interface ScheduleConnection {
  meetingLocation: string;
  connectionStartTime: Date | null;
  connectionTags: string[];
  connectionType: string;
  title: string;
  spaceId: number | undefined;
  postEvent: boolean;
  selectedDate: string;
  selectedTime: string;
  selectedTimezone: string;
}

function StartOrScheduleConnection ({
  space,
  connection,
  spaces
}: StartOrScheduleConnectionProps) {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  const createConnect = useCreateConnectMutation()
  const updateConnect = useUpdateConnectionMutation()

  const connectionTypes = {
    SECRET: 'Secret',
    PUBLIC: 'Public'
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [scheduleConnection, setScheduleConnection] =
    useState<ScheduleConnection>(() => {
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      tomorrow.setMinutes(0)
      tomorrow.setSeconds(0)
      tomorrow.setMilliseconds(0)

      return {
        meetingLocation: space?.location || connection?.location || '',
        connectionStartTime: parseDefaultedDate(connection?.startTime),
        connectionTags:
          connection?.connectionTags?.map(
            (connectionTag) => connectionTag?.tag?.name ?? ''
          ) || [],
        connectionType:
          connection?.connectionType || connectionTypes.SECRET.toUpperCase(),
        title: connection?.title || '',
        spaceId: space?.id || connection?.spaceId || spaces?.[0]?.id,
        postEvent: true,
        selectedDate: connection?.startTime
          ? new Date(connection.startTime).toISOString().split('T')[0]
          : tomorrow.toISOString().split('T')[0],
        selectedTime: connection?.startTime
          ? new Date(connection.startTime).toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit'
          })
          : tomorrow.toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit'
          }),
        selectedTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    })

  const connectionTypeDescriptions = {
    SECRET: `Only ${Entity.Space} members can see this ${Entity.Event}`,
    PUBLIC: `Everyone in the ${Entity.Community} can see this ${Entity.Event}. When someone joins this ${Entity.Event}, they will also become a member of the "${
      space?.name ||
      spaces?.find((space) => space.id === scheduleConnection.spaceId)?.name
    }" ${Entity.Space}`
  }

  const connectTypeButtonText = 'Schedule'

  const timezones = useMemo(() => {
    return (
      (Intl as any)
        ?.supportedValuesOf('timeZone')
        ?.map((timezone) => {
          const date = new Date()
          const utcDate = new Date(
            date.toLocaleString('en-US', { timeZone: 'UTC' })
          )
          const tzDate = new Date(
            date.toLocaleString('en-US', { timeZone: timezone })
          )
          const offset = (tzDate.getTime() - utcDate.getTime()) / 3600000

          const timezoneName =
            new Date()
              .toLocaleString('en-US', {
                timeZone: timezone,
                timeZoneName: 'short'
              })
              .split(' ')
              .pop() || timezone.split('/').pop()

          const city = timezone.split('/').pop()?.replace(/_/g, ' ')
          return {
            timezone,
            label: `(${timezoneName}) ${city}`,
            offset
          }
        })
        .sort((a, b) => b.offset - a.offset) ?? []
    )
  }, [])

  function handleChange<K extends keyof ScheduleConnection> (
    key: K,
    value: ScheduleConnection[K]
  ) {
    setScheduleConnection((prevData) => ({
      ...prevData,
      [key]: value
    }))
    setErrorMessage('')
  }

  function combineDateTime (): Date | null {
    if (!scheduleConnection.selectedDate || !scheduleConnection.selectedTime) {
      return null
    }

    const dateTimeString = `${scheduleConnection.selectedDate}T${scheduleConnection.selectedTime}`
    return fromZonedTime(dateTimeString, scheduleConnection.selectedTimezone)
  }

  async function handleStartOrSchedule () {
    const combinedDateTime = combineDateTime()
    if (!combinedDateTime) {
      setErrorMessage('Please select both a date and time for the connection.')
      return
    }

    if (!scheduleConnection.spaceId) {
      setErrorMessage('Please select a space for the connection.')
      return
    }

    if (
      scheduleConnection.title.trim() === '' &&
      scheduleConnection.connectionType !== connectionTypes.SECRET.toUpperCase()
    ) {
      setErrorMessage('Please enter a title for the connection.')
      return
    }

    if (connection) {
      const updatedScheduleConnection = {
        location: scheduleConnection.meetingLocation,
        startTime: combinedDateTime,
        title: scheduleConnection.title,
        connectionTags: scheduleConnection.connectionTags,
        connectionType: scheduleConnection.connectionType,
        connectionUid: connection?.uid ?? '',
        postEvent: scheduleConnection.postEvent
      }
      await updateConnect.mutateAsync(updatedScheduleConnection)
      await handleNavigation()
    } else {
      const createdScheduleConnection = {
        location: scheduleConnection.meetingLocation,
        startTime: combinedDateTime,
        title: scheduleConnection.title,
        connectionTags: scheduleConnection.connectionTags,
        connectionType: scheduleConnection.connectionType,
        spaceId: scheduleConnection.spaceId,
        postEvent: scheduleConnection.postEvent
      }
      await createConnect.mutateAsync(createdScheduleConnection)
      await handleNavigation()
    }
  }

  async function handleNavigation () {
    await queryClient.invalidateQueries(['spaces'])
    clearModal()
  }

  return (
    <>
      {errorMessage && (
        <div className="create-connection-error">{errorMessage}</div>
      )}

      <div className="connect-metaData">
        <input
          value={scheduleConnection.title}
          onChange={(e) => handleChange('title', e.target.value)}
          placeholder="Event name"
          autoFocus
        />
      </div>
      <div className="connect-metaData">
        <label>Start time</label>
        <div className="datetime-inputs">
          <input
            type="date"
            value={scheduleConnection.selectedDate}
            onChange={(e) => handleChange('selectedDate', e.target.value)}
            min={new Date().toISOString().split('T')[0]}
          />
          <input
            type="time"
            value={scheduleConnection.selectedTime}
            onChange={(e) => handleChange('selectedTime', e.target.value)}
          />
          <select
            value={scheduleConnection.selectedTimezone}
            onChange={(e) => handleChange('selectedTimezone', e.target.value)}
          >
            {timezones.map(({ timezone, label }) => (
              <option key={timezone} value={timezone}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="connect-metaData">
        <label>Where are you meeting?</label>
        <GoogleAddressSearch
          address={scheduleConnection.meetingLocation}
          placeholder="Location, video call link, etc..."
          onChange={(e) => handleChange('meetingLocation', e.target.value)}
          setAddress={(meetingLocation) =>
            setScheduleConnection((previous) => ({
              ...previous,
              meetingLocation
            }))
          }
        />
      </div>
      {spaces && !connection && (
        <div className="connect-metaData">
          <label>Associate with {Entity.Space}</label>
          <select
            value={scheduleConnection.spaceId}
            onChange={(e) => handleChange('spaceId', parseInt(e.target.value))}
          >
            {spaces.map((space) => (
              <option key={space.id} value={space.id}>
                {space.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="connect-metaData">
        <label>Event privacy</label>
        <div className="privacy-selector">
          <select
            value={scheduleConnection.connectionType}
            onChange={(e) => handleChange('connectionType', e.target.value)}
          >
            {Object.keys(connectionTypes).map((type) => (
              <option key={type} value={type}>
                {connectionTypes[type]}
              </option>
            ))}
          </select>
          <div className="privacy-description">
            {
              connectionTypeDescriptions[
                scheduleConnection.connectionType as keyof typeof connectionTypeDescriptions
              ]
            }
          </div>
        </div>
      </div>
      {/* <div className="connect-metaData">
        <Autocomplete
          multiple
          id="tags-standard"
          options={
            Array.isArray(tags)
              ? tags.map((eventTag) => eventTag?.tag?.name ?? '')
              : []
          }
          freeSolo
          value={scheduleConnection.connectionTags}
          onChange={(event, newValue) => {
            handleChange('connectionTags', newValue)
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" placeholder="Tags..." />
          )}
        />
      </div> */}
      <div className="connect-metaData">
        <label>Post {Entity.Event} to Feed</label>
        <Switch
          disabled={connection?.postEvent}
          checked={scheduleConnection.postEvent}
          onChange={(e) => handleChange('postEvent', e.target.checked)}
        />
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={clearModal}>
          Cancel
        </div>
        <div
          className="button"
          data-testid="modal-end-connection-button"
          onClick={handleStartOrSchedule}
        >
          {connectTypeButtonText}
        </div>
      </div>
    </>
  )
}

export default StartOrScheduleConnection
