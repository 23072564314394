import { useNavigate } from 'react-router-dom'
import { useRef, useState } from 'react'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import {
  canCreateOrganization,
  getActiveOrganizations,
  getCurrentOrganization,
  openSupportChat,
  useSwitchOrganization,
  useOutsideAlerter
} from '../../shared/utilities'
import { Paper, Divider, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { isAuthorized } from '../../shared/permissions'
import { config } from '../../shared/config'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Check from '@mui/icons-material/CheckRounded'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import Logo from '../Logo'
import { useAuthContext } from '../../context/AuthContext'
import './index.css'
import { Entity } from '../../shared/enums'
import { isFeatureEnabled } from '../../shared/posthog'
import { Feature } from '../../shared/features'

interface OrganizationSelectionMenuProps {
  onlyOrganizations?: boolean
  className?: string
}

function OrganizationSelectionMenu ({ onlyOrganizations, className }: OrganizationSelectionMenuProps) {
  const navigate = useNavigate()
  const [select, setSelect] = useState(false)
  const wrapperRef = useRef(null)
  const { switchOrganization } = useSwitchOrganization()
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const activeOrganizations = getActiveOrganizations(currentUser)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setSelect })

  function generateNavIcon () {
    const name = currentOrganization?.name || 'Unknown'
    return (
      <>
        <Logo logoUrl={currentOrganization?.logoUrl} name={name} alt={'organization logo'} />
        <h1 className="program-name">{name}</h1>
      </>
    )
  }

  function navigateToStudio () {
    window.open(`${config.STUDIO_HOST}/?organization=${currentUser?.currentOrganizationId}`, '_blank')
  }

  const selectOrganization = (organizationId) => {
    switchOrganization(organizationId)
    setSelect(false)
    navigate('/')
    window.location.reload()
  }

  const navigateAndClose = (path) => {
    setSelect(false)
    navigate(path)
  }

  const orgDropdownMenu = activeOrganizations?.map((org) => {
    if (!org) return <></>
    return (
      <MenuItem key={org.id} onClick={() => selectOrganization(org.id)}>
        {currentUser?.currentOrganizationId === org.id
          ? (
            <>
              <ListItemIcon>
                <Check />
              </ListItemIcon>{' '}
              {org.name}
            </>
            )
          : (
          <ListItemText inset>{org.name}</ListItemText>
            )}
      </MenuItem>
    )
  })

  if (!currentOrganization) {
    return null
  }

  return (
    <div ref={wrapperRef} className={className}>
      <div data-testid="organization-dropdown" className="nav-title" onClick={() => setSelect(!select)}>
        {generateNavIcon()}
        <span style={{ marginTop: '0.25rem' }}>
          <ExpandMoreRoundedIcon />
        </span>
      </div>
      {select && (
        <div className="dropdown">
          <Paper sx={{ width: 250 }} elevation={24}>
            <MenuList dense data-testid="organization-menu">
              <MenuItem disabled>{Entity.Community}</MenuItem>
              {activeOrganizations.length > 1 && (
                <>
                  {orgDropdownMenu}
                  <Divider />
                </>
              )}
              {!onlyOrganizations && (
                <div>
                  {isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser) && (
                    <div>
                      <MenuItem onClick={() => navigateAndClose('/communities/settings')}>
                        <ListItemText>Settings</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => navigateAndClose('/communities/settings/billing')}>
                        <ListItemText>Billing</ListItemText>
                      </MenuItem>
                      <Divider />
                      <MenuItem disabled>Administration</MenuItem>
                      <MenuItem onClick={() => navigateAndClose('/communities/settings/people')}>
                        <ListItemText>People</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => navigateAndClose('/communities/settings/spaces')}>
                        <ListItemText>Spaces</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => navigateAndClose('/communities/settings/library')}>
                        <ListItemText>Library</ListItemText>
                      </MenuItem>
                      {isFeatureEnabled(Feature.Goals) && (
                        <MenuItem onClick={() => navigateAndClose('/communities/settings/goals')}>
                          <ListItemText>Goals</ListItemText>
                        </MenuItem>
                      )}
                      <Divider />
                      <MenuItem disabled>Tools</MenuItem>
                      <MenuItem onClick={() => navigateAndClose('/communities/settings/reports')}>
                        <ListItemText>Reports</ListItemText>
                      </MenuItem>
                      {currentOrganization?.studioAccess && (
                        <MenuItem onClick={() => navigateToStudio()}>
                          <ListItemText className="studio-access">
                            Studio <LaunchRoundedIcon fontSize="small" color="primary" />
                          </ListItemText>
                        </MenuItem>
                      )}
                      <Divider />
                    </div>
                  )}
                  {isAuthorized(getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser) && (
                    <MenuItem onClick={openSupportChat}>
                      <ListItemText>Support</ListItemText>
                    </MenuItem>
                  )}
                  <MenuItem onClick={openSupportChat}>
                    <ListItemText>Give Feedback</ListItemText>
                  </MenuItem>
                  {canCreateOrganization() && (
                    <>
                      <Divider />
                      <MenuItem onClick={() => navigateAndClose('/communities/new')}>
                        <ListItemText>New {Entity.Community}</ListItemText>
                      </MenuItem>
                    </>
                  )}
                </div>
              )}
            </MenuList>
          </Paper>
        </div>
      )}
    </div>
  )
}

export default OrganizationSelectionMenu
