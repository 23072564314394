import { useDailyReportQuery } from '../../../shared/queryHooks'
import { formatDateString, getCurrentOrganization } from '../../../shared/utilities'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthContext } from '../../../context/AuthContext'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SecondaryNavBar from '../../../components/SecondaryNavBar'
import LoadingScreen from '../../../components/LoadingScreen'
import ErrorPage from '../../ErrorPage'
import insightsIllustration from '../../../assets/insights.svg'
import EmptyHero from '../../../components/EmptyHero'
import './index.css'

function ReportsDailyDetail () {
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const dayLocal = parseInt(decodeURIComponent(searchParams.get('start') ?? '0'))
  const nextDayLocal = parseInt(decodeURIComponent(searchParams.get('next') ?? '0'))

  const { isLoading, isError, data: reports, error } = useDailyReportQuery(currentOrganization?.id ?? 0, dayLocal, nextDayLocal)

  if (isLoading) {
    return (
      <>
        <SecondaryNavBar backTo={'/communities/settings/reports'} title="Daily Report" subtitle={`${formatDateString(dayLocal)}`} />
        <LoadingScreen />
      </>
    )
  }

  if (isError) {
    const e: any = error
    const message = e?.request?.status === 404 ? '404, this page does not exist' : 'Something went wrong'
    return (
      <>
        <SecondaryNavBar backTo={'/communities/settings/reports'} title="Daily Report" subtitle={`${formatDateString(dayLocal)}`} />
        <ErrorPage message={message} action={() => navigate('/')} actionTitle="Return home?" />
      </>)
  }

  const responseDisplay = reports?.dailyProgress?.map(data => {
    return (
      <div key={data?.id} className="response-container-item">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{data?.firstName} {data?.lastName}</Typography>
          </AccordionSummary>
          {data?.description_response?.map((entry, index) => {
            return (
              <AccordionDetails key={index}>
                <label className="description">{entry?.description}</label>
                <div className="response">{entry?.response}</div>
              </AccordionDetails>
            )
          })}
        </Accordion>
      </div>
    )
  })

  const percentageParticipation = `${Math.round(reports?.percentageCompleted * 100)}%`
  const percentageDisplay = (
    <div className="daily-history-container participation-display-container">
      <div className="daily-insight-header">
        <div className="percentage-container">{percentageParticipation}</div>
        <div className="participation-title">Participation</div>
      </div>
      <div className="progress-bar-display">
          <div className="progress-bar-display-inner" style={{ width: percentageParticipation }}></div>
        </div>
    </div>
  )
  const healthPercentage = `${Math.round(reports?.healthPercentage * 100)}%`
  const healthDisplay = (
    <div className="daily-history-container health-display-container">
      <div className="daily-insight-header">
        <div className="percentage-container">{healthPercentage}</div>
        <div className="participation-title">Health</div>
      </div>
      <div className="progress-bar-display">
          <div className="progress-bar-display-inner" style={{ width: healthPercentage }}></div>
        </div>
    </div>
  )

  return (
    <>
      <SecondaryNavBar backTo={'/communities/settings/reports'} title="Daily Report" subtitle={`${formatDateString(dayLocal)}`} />
      <div className="reports-overview-header report-nav">
        {reports?.dailyProgress?.length > 0 && percentageDisplay}
        {reports?.dailyProgress?.length > 0 && healthDisplay}
      </div>
      <div className="weekly-report-container">
        {reports?.dailyProgress?.length > 0 &&
          <div className="response-container">
            <h2>Daily Check-in</h2>
            {responseDisplay}
          </div>}
        {!reports?.dailyProgress || reports.dailyProgress.length === 0
          ? (
          <EmptyHero
            image={insightsIllustration}
            title="Daily Check-in"
            description="You don't have any check-in's for this day. Once daily check-in's have been completed, analytics will appear here." />
            )
          : <></>}
      </div>
    </>
  )
}

export default ReportsDailyDetail
