import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState
} from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useModal } from '../../context/ModalContext'
import SettingsCell from '../SettingsCell'
import ManageUserSpaces from '../../modals/ManageUserSpaces'
import { roles, rolesDisplayMap } from '../../shared/enums'
import Avatar from '../Avatar'
import './index.css'
import { OrganizationRole } from '../../types'
import { useAuthContext } from '../../context/AuthContext'
import Table from '../Table'
import { Checkbox } from '@mui/material'
import { getCurrentOrganizationRole, getPersonStatus, personStatusIconMap } from '../../shared/utilities'

interface PeopleTableProps {
  data: OrganizationRole[];
  tags: string[];
}

const PersonNameCell = ({ row }) => {
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()
  const myPerson = currentUser?.organizationRoles?.find(
    (role) => role?.organization?.id === currentUser?.currentOrganizationId
  )

  const email = row.original.contact?.email || row.original.user?.email
  return (
    <div
      className="contact-details-cell"
      style ={{ marginLeft: '5px' }}
      onClick={() =>
        navigate(`/communities/settings/people/${row.original?.id}`)
      }
      data-testid={`${row.original.contact?.firstName}-${row.original.contact?.lastName}-person-cell`}
    >
      <Avatar
        className="person-avatar"
        firstName={row.original.contact?.firstName}
        lastName={row.original.contact?.lastName}
        avatar={row.original.user?.avatar}
      />
      <div className="contact-details-container">
        <div className="contact-fullname">
          {row.original.contact?.firstName} {row.original.contact?.lastName}
          <span style={{ opacity: 0.6, marginLeft: '0.25rem' }}>
            {row.original?.id === myPerson?.id ? '(You)' : ''}
          </span>
        </div>
        {email && <div className="contact-email">{email}</div>}
      </div>
    </div>
  )
}

const PersonStatusCell = ({ row }) => {
  return (
    <div
      data-testid={`${row.original.contact?.firstName}-${row.original.contact?.lastName}-status-cell`}
      style={{ minWidth: '11rem', display: 'flex', alignItems: 'center', gap: '0.25rem' }}
    >
      {personStatusIconMap[getPersonStatus(row.original)]}
      {getPersonStatus(row.original)}
    </div>
  )
}

const PersonRoleCell = ({ row }) => {
  return (
    <div
      style={{ minWidth: '7rem' }}
      data-testid={`${row.original.contact?.firstName}-${row.original.contact?.lastName}-role-cell`}
    >
      {row.original.primaryOwner ? `${rolesDisplayMap[row.original.title]} (Primary)` : rolesDisplayMap[row.original.title]}
    </div>
  )
}

const PersonSpacesCell = ({ row }) => {
  const { makeModal } = useModal()
  const personIsActive = row.original?.status === 'ACTIVE'

  if (!personIsActive) {
    return ''
  }

  return (
    <div
      data-testid={`${row.original.contact?.firstName}-${row.original.contact?.lastName}-spaces-cell`}
      className="member-spaces"
      onClick={() =>
        makeModal({
          modalSize: 'LARGE',
          modal: <ManageUserSpaces person={row.original} />
        })
      }
    >
      {row.original.spaceRoles?.length}
    </div>
  )
}

function PeopleTable ({ data, tags }: PeopleTableProps) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = useState<string>('')
  const [editPersonId, setEditPersonId] = useState<number | null>(null)
  const owners = data?.filter((person) => person?.title === roles.owner)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [selectedOrganizationRoles, setSelectedOrganizationRoles] = useState<number[]>([])
  const { currentUser } = useAuthContext()
  const currentOrganizationRole = getCurrentOrganizationRole(currentUser)
  const title = 'People'

  function makeFilteredData () {
    if (selectedTags.length === 0) {
      return data
    }

    const filteredData = data.filter((person) =>
      person.contact?.tags?.some((contactTag) => selectedTags.includes(contactTag?.tag?.name ?? ''))
    )
    return filteredData
  }

  const fuzzyFilter = (row, columnId, value) => {
    if (columnId !== 'fullName') return false
    return (
      row
        .getValue(columnId)
        .toString()
        .toLowerCase()
        .indexOf(value.toLowerCase()) !== -1
    )
  }

  const personSelection = (value: number | null) => {
    setEditPersonId(value)
  }

  function handleSelectAll (selectOption: boolean) {
    if (selectOption) {
      setSelectedOrganizationRoles(data.filter(person => person.title !== 'OWNER' && person.id !== currentOrganizationRole?.id).map((role) => role.id))
    } else {
      setSelectedOrganizationRoles([])
    }
    setSelectAll(selectOption)
  }

  function handleChange (id: number) {
    if (selectedOrganizationRoles.includes(id)) {
      setSelectedOrganizationRoles(selectedOrganizationRoles.filter((role) => role !== id))
    } else {
      setSelectedOrganizationRoles([...selectedOrganizationRoles, id])
    }
    setSelectAll(false)
  }

  const columns = [
    {
      header: 'Person',
      id: 'fullName',
      accessorFn: (row) =>
        `${row.contact?.firstName} ${row.contact?.lastName} ${row.contact?.email}`,
      cell: ({ row }) => (
          <div style={{ display: 'flex', marginLeft: (row.original.title === 'OWNER' || row.original.id === currentOrganizationRole?.id) ? '2.62rem' : '' }}>
            {row.original.title !== 'OWNER' && row.original.id !== currentOrganizationRole?.id && <Checkbox checked={selectedOrganizationRoles.includes(row.original.id)} onChange={() => handleChange(row.original.id)}/>}
            <PersonNameCell row={row} />
          </div>
      )
    },
    {
      header: 'Status',
      accessorFn: (row) => row.status,
      cell: ({ row }) => <PersonStatusCell row={row} />
    },
    {
      header: 'Role',
      accessorFn: (row) => row.title,
      cell: ({ row }) => <PersonRoleCell row={row} />
    },
    {
      header: 'Spaces',
      accessorFn: (row) => row.spaceRoles?.length,
      cell: ({ row }) => <PersonSpacesCell row={row} />
    },
    {
      header: '',
      id: 'userId',
      cell: ({ row }) => (
        <SettingsCell
          person={row.original}
          editPersonId={editPersonId}
          personSelection={personSelection}
          owners={owners}
          setExpandPersonSettingsView={() => {}}
          requiresExpandedSettingsDisplay={
            table.getRowModel().rows.indexOf(row) >
            table.getRowModel().rows.length - 4
          }
        />
      ),
      enableSorting: false
    }
  ]

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <>
      <Table
        typeReferenceText={title}
        fuzzyFilter={fuzzyFilter}
        columns={columns}
        data={makeFilteredData()}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        tags={tags}
        tableWrapperClass='people-table-container'
        selectAll={selectAll}
        handleSelectAll={handleSelectAll}
        displaySelect={true}
        selectedOrganizationRoles={selectedOrganizationRoles}
      />
    </>
  )
}

export default PeopleTable
