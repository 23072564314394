import {
  useEventsQuery,
  useEventTagsQuery,
  useSpacesQuery
} from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import LoadingScreen from '../../components/LoadingScreen'
import { logError } from '../../shared/logger'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ListIcon from '@mui/icons-material/List'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import EventsList from '../../components/EventsList'
import EventsCalendar from '../../components/EventsCalendar'
import SecondaryNavBar from '../../components/SecondaryNavBar'
import { NavigationAction } from '../../types'
import { useModal } from '../../context/ModalContext'
import { isAuthorized } from '../../shared/permissions'
import { getLeaderPolicy } from '../../shared/policies'
import StartOrScheduleConnection from '../../modals/StartOrScheduleConnection'
import JoinEvent from '../../modals/JoinEvent'
import { Event } from '../../types/DataTypes'
import FilterEvents from '../../components/FilterEvents'
import EmptyHero from '../../components/EmptyHero'
import connectionsIllustration from '../../assets/connections.svg'
import './index.css'

function Events () {
  const { currentUser } = useAuthContext()
  const isHostAndAbove = isAuthorized(getLeaderPolicy(), currentUser)
  const { makeModal } = useModal()
  const navigate = useNavigate()
  const {
    isError,
    data: events,
    isLoading,
    error
  } = useEventsQuery({ enabled: !!currentUser })
  const {
    isError: isTagsError,
    data: eventTags,
    isLoading: isEventTagsLoading,
    error: tagsError
  } = useEventTagsQuery({ enabled: !!currentUser && !!events })
  const {
    isError: isSpacesError,
    data: spaces,
    isLoading: isSpacesLoading,
    error: spacesError
  } = useSpacesQuery(currentUser?.currentOrganizationId ?? 0, {
    enabled: !!currentUser && !!events && !!eventTags,
    filter: 'where-member'
  })
  const [filteredEvents, setFilteredEvents] = useState<Event[] | null>(null)

  const eventTypes = {
    list: 'list',
    calendar: 'calendar'
  }

  const [eventType, setEventType] = useState(eventTypes.list)

  if (isLoading || isEventTagsLoading || isSpacesLoading) {
    return <LoadingScreen />
  }

  if (isError || isTagsError || isSpacesError) {
    logError(error || tagsError || spacesError)
    return <SecondaryNavBar title="Events" />
  }

  const emptyHeroText = spaces?.length > 0
    ? 'Events are a great way to connect with your community. Create an event to get started.'
    : 'Spaces are required to create events. Create a space to get started.'

  function handleJoinEvent (event: Event) {
    makeModal({
      modal: <JoinEvent event={event} />,
      title: 'Join Event'
    })
  }

  function handleSort (selectedTags: string[]) {
    if (selectedTags.length === 0) {
      setFilteredEvents(null)
      return
    }

    if (events !== null || events !== undefined) {
      const filteredEventsData = events?.filter((event) =>
        event.connectionTags?.some((connectionTag) =>
          selectedTags.includes(connectionTag?.tag?.name ?? '')
        )
      )
      setFilteredEvents(filteredEventsData ?? [])
    }
  }

  const getSubnavAction = () => {
    const actions: NavigationAction[] = []

    if (eventTags && eventTags?.length > 0 && events && events?.length > 0) {
      actions.push({
        action: () => {},
        actionTitle: (
          <FilterEvents
            tags={eventTags.map((eventTag) => eventTag.tag.name)}
            handleSort={handleSort}
          />
        ),
        actionClassName: 'secondary filter-events'
      })
    }

    if (events && events?.length > 0) {
      actions.push(
        {
          action: () => setEventType(eventTypes.list),
          actionTitle: <ListIcon fontSize="small" />,
          actionClassName: 'secondary list-filter-icon filter-events-button'
        },
        {
          action: () => setEventType(eventTypes.calendar),
          actionTitle: <CalendarTodayIcon fontSize="small" />,
          actionClassName: 'secondary calendar-filter-icon filter-events-button'
        }
      )
    }

    if (isHostAndAbove && spaces?.length > 0) {
      actions.push({
        action: () =>
          makeModal({
            modal: (
              <StartOrScheduleConnection
                spaces={spaces}
                tags={eventTags}
              />
            ),
            title: 'Create Event',
            disableFocus: true,
            modalSize: 'LARGE'
          }),
        actionTitle: 'Create Event',
        actionClassName: 'secondary'
      })
    }
    return actions
  }

  const emptyHeroAction =
    isHostAndAbove && spaces?.length > 0
      ? [
          {
            onClick: () =>
              makeModal({
                modal: (
                  <StartOrScheduleConnection
                    spaces={spaces}
                    tags={eventTags}
                  />
                ),
                title: 'Create Event',
                disableFocus: true,
                modalSize: 'LARGE'
              }),
            actionTitle: 'Create Event'
          }
        ]
      : [
          {
            onClick: () => navigate('/spaces'),
            actionTitle: 'Create a Space'
          }
        ]

  const getEventsDisplay = () => {
    if (eventType === eventTypes.list) {
      return (
        <EventsList
          events={
            filteredEvents !== null && filteredEvents?.length > 0
              ? filteredEvents
              : events ?? []
          }
          handleJoinEvent={handleJoinEvent}
        />
      )
    }

    if (eventType === eventTypes.calendar) {
      return (
        <EventsCalendar
          events={
            filteredEvents !== null && filteredEvents?.length > 0
              ? filteredEvents
              : events ?? []
          }
          handleJoinEvent={handleJoinEvent}
        />
      )
    }
  }

  return (
    <>
      <SecondaryNavBar title="Events" onClickActions={getSubnavAction()} />
      {events && events?.length > 0 && getEventsDisplay()}
      {(!events || events?.length === 0) && (
        <EmptyHero
          image={connectionsIllustration}
          title="Events"
          action={emptyHeroAction}
          description={emptyHeroText}
        />
      )}
    </>
  )
}

export default Events
