import {
  sortSpaceRolesByFirstName
} from '../../shared/utilities'
import SpaceUserItem from '../SpaceUserItem'
import { useAuthContext } from '../../context/AuthContext'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { useModal } from '../../context/ModalContext'
import { useUpdateSpaceSpaceRolesMutation } from '../../shared/queryHooks'
import { useState, useEffect } from 'react'
import './index.css'
import { Space, SpaceRole, SpaceTitle } from '../../types'
import { PuffLoader } from 'react-spinners'
import { getSpaceLeaderPolicy } from '../../shared/policies'
import { isAuthorized } from '../../shared/permissions'
import AddOrEditSpaceroles from '../../modals/AddOrEditSpaceroles'

function SpaceMembersDisplay ({ space }: { space: Space }) {
  const { makeModal, clearModal } = useModal()
  const { currentUser } = useAuthContext()
  const updateSpaceRoles = useUpdateSpaceSpaceRolesMutation()
  const [currentSpaceRoles, setCurrentSpaceRoles] = useState<SpaceRole[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const isSpaceLeader = isAuthorized(
    getSpaceLeaderPolicy(space.id, currentUser?.currentOrganizationId),
    currentUser
  )

  useEffect(() => {
    setCurrentSpaceRoles(
      space?.spaceRoles?.sort(sortSpaceRolesByFirstName) || []
    )
  }, [space])

  function selectRole ({
    title,
    spaceRoleId
  }: {
    title: SpaceTitle;
    spaceRoleId: number;
  }) {
    setErrorMessage('')
    if (title === 'MEMBER') {
      const isLastLeader = currentSpaceRoles.filter(role => role.title === 'LEADER').length === 1 &&
        currentSpaceRoles.find(role => role.id === spaceRoleId)?.title === 'LEADER'

      if (isLastLeader) {
        setErrorMessage('There must be one Host for a space')
        return
      }
    }

    setCurrentSpaceRoles(
      currentSpaceRoles.map((spaceRole) => {
        if (spaceRole?.id === spaceRoleId) {
          return { ...spaceRole, title }
        }
        return spaceRole
      })
    )
  }

  function handleSave () {
    updateSpaceRoles.mutate({
      spaceId: space.id,
      spaceRoles: currentSpaceRoles
    })
    clearModal()
  }

  return (
    <>
      <div className="space-members-container">
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-start',
            width: '100%'
          }}
        >
          {isSpaceLeader && (
            <div
              className="invite-member-button"
              onClick={() =>
                makeModal({
                  modal: <AddOrEditSpaceroles existingSpace={space} />,
                  title: `Space Members for ${space?.name}`,
                  className: 'manage-space-modal'
                })
              }
            >
              <PersonAddIcon />
              Add People
            </div>
          )}
        </div>
        {errorMessage && <div className="space-members-role-error">{errorMessage}</div>}
        <div className="space-members-list">
          {currentSpaceRoles.map((spaceRole) => (
            <SpaceUserItem
              key={spaceRole?.organizationRoleId}
              spaceRole={spaceRole}
              selectRole={selectRole}
            />
          ))}
        </div>
      </div>
      <div className="modal-footer">
        {isSpaceLeader
          ? (
          <div className="button" onClick={handleSave}>
            {updateSpaceRoles.isLoading ? <PuffLoader /> : 'Save'}
          </div>
            )
          : (
          <div className="button secondary" onClick={() => clearModal()}>
            Close
          </div>
            )}
      </div>
    </>
  )
}

export default SpaceMembersDisplay
