import SecondaryNavBar from '../../../components/SecondaryNavBar'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import EditOrganizationRolesDailyCheckin from '../../../modals/EditOrganizationRolesDailyCheckin'
import {
  useUpdateOrganizationMutation,
  useUpdateDailyCheckinMutation
} from '../../../shared/queryHooks'
import { getCurrentOrganization } from '../../../shared/utilities'
import { useAuthContext } from '../../../context/AuthContext'
import { useQueryClient } from '@tanstack/react-query'
import { PuffLoader } from 'react-spinners'
import { Switch } from '@mui/material'
import { useState, useEffect } from 'react'
import { logError } from '../../../shared/logger'
import { useModal } from '../../../context/ModalContext'
import { getOrganizationRoleDailyCheckins } from '../../../shared/api'
import './index.css'
import { Checkin, DailyCheckinDaysOfWeek } from '../../../types'
import { parsedErrorMessage } from '../../../shared/errors'
import { toast } from 'sonner'
import { defaultDailyCheckinDaysOfWeek } from '../../../shared/enums'

function OrganizationSettingsActions () {
  const { currentUser } = useAuthContext()
  const { makeModal } = useModal()
  const currentOrganization = getCurrentOrganization(currentUser)
  const queryClient = useQueryClient()
  const editOrganization = useUpdateOrganizationMutation()
  const editDailyCheckins = useUpdateDailyCheckinMutation()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [errorDayMessage, setErrorDayMessage] = useState<string>('')
  const [dailyCheckins, setDailyCheckins] = useState<Checkin[]>([])

  const getSafeDailyCheckinDaysOfWeek = (): DailyCheckinDaysOfWeek => {
    try {
      return JSON.parse(
        currentOrganization?.dailyCheckinDaysOfWeek ||
          JSON.stringify(defaultDailyCheckinDaysOfWeek)
      )
    } catch (e) {
      return defaultDailyCheckinDaysOfWeek
    }
  }

  const [dailyCheckinDaysOfWeek, setDailyCheckinDaysOfWeek] =
    useState<DailyCheckinDaysOfWeek>(getSafeDailyCheckinDaysOfWeek())

  async function getDailyCheckins () {
    return await queryClient.fetchQuery(['checkin'], () =>
      getOrganizationRoleDailyCheckins()
    )
  }

  useEffect(() => {
    const fetchDailyCheckins = async () => {
      const dailyCheckinData = await getDailyCheckins()
      setDailyCheckins(dailyCheckinData)
    }
    void fetchDailyCheckins()
  }, [])

  function handleChange (id: number, description: string) {
    setErrorMessage('')
    setDailyCheckins((prevState) =>
      prevState.map((state) =>
        state.id === id ? { ...state, description } : state
      )
    )
  }

  function handleKeyChange (key: string) {
    const dailyCheckinDaysObj = dailyCheckinDaysOfWeek
    const newDailyCheckinDayPreferences = {
      ...dailyCheckinDaysObj,
      [key]: !dailyCheckinDaysObj[key]
    }
    setDailyCheckinDaysOfWeek(newDailyCheckinDayPreferences)
  }

  async function toggleDailyCheckins (dailyCheckin: boolean) {
    await editOrganization.mutateAsync({
      name: currentOrganization?.name ?? '',
      organizationId: currentUser?.currentOrganizationId ?? 0,
      memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
      logoUrl: currentOrganization?.logoUrl ?? '',
      dailyCheckin,
      dailyCheckinDaysOfWeek: JSON.stringify(dailyCheckinDaysOfWeek),
      editConnection: currentOrganization?.editConnection,
      defaultSpace: currentOrganization?.defaultSpace ?? false,
      shareContactData: currentOrganization?.shareContactData ?? false,
      membersCanInvite: currentOrganization?.membersCanInvite ?? false
    })
    await queryClient.invalidateQueries()
  }

  async function updateDailyCheckinDays () {
    try {
      await editOrganization.mutateAsync({
        name: currentOrganization?.name ?? '',
        organizationId: currentUser?.currentOrganizationId ?? 0,
        memberSpaceCreation: currentOrganization?.memberSpaceCreation ?? false,
        logoUrl: currentOrganization?.logoUrl ?? '',
        dailyCheckin: currentOrganization?.dailyCheckin,
        dailyCheckinDaysOfWeek: JSON.stringify(dailyCheckinDaysOfWeek),
        editConnection: currentOrganization?.editConnection,
        defaultSpace: currentOrganization?.defaultSpace ?? false,
        shareContactData: currentOrganization?.shareContactData ?? false,
        membersCanInvite: currentOrganization?.membersCanInvite ?? false
      })
      await queryClient.invalidateQueries()
      toast.success('Your changes have been saved')
    } catch (error) {
      logError(error)
      setErrorDayMessage(parsedErrorMessage(error))
    }
  }

  async function submitDailyCheckins () {
    const isEmpty = dailyCheckins?.filter(
      (checkin) => checkin?.description.trim() === ''
    )
    if (isEmpty?.length > 0) {
      setErrorMessage(
        'Please fill out all check-in descriptions before submitting'
      )
      return
    }

    try {
      await editDailyCheckins.mutateAsync(dailyCheckins)
      toast.success('Your changes have been saved')
    } catch (error) {
      logError(error)
      setErrorMessage(parsedErrorMessage(error))
    }
  }

  function editOrganizationRoleDailyCheckinAccessModal () {
    makeModal({
      modal: <EditOrganizationRolesDailyCheckin />,
      title: 'Members that Receive the Check-in'
    })
  }

  const dailyCheckinFormFields = dailyCheckins
    .filter((checkin) => checkin.responseType === 'TEXT')
    .map((checkin) => {
      return (
        <div key={checkin.id} className="daily-checkin-form-input">
          <input
            type="text"
            placeholder="Check-in description..."
            onChange={(e) => handleChange(checkin.id, e.target.value)}
            value={checkin.description}
          />
        </div>
      )
    })

  const getDailyCheckinDaysOfWeek = () => {
    try {
      return new Array(dailyCheckinDaysOfWeek) ?? []
    } catch (e) {
      return []
    }
  }

  const daysOfTheWeekDisplay = getDailyCheckinDaysOfWeek().map((day) => {
    const daysOfWeek = Object.keys(day)
    const dayDisplay = daysOfWeek.map((data) => {
      return (
        <div
          key={data}
          className={
            dailyCheckinDaysOfWeek[data] ? 'day-selected' : 'day-unselected'
          }
          onClick={() => handleKeyChange(data)}
        >
          {data.toUpperCase()}
        </div>
      )
    })
    return dayDisplay
  })

  return (
    <>
      <SecondaryNavBar title="Actions" />
      <div className="profile-settings-wrapper org-nav-settings">
        <div className="profile-setting-container action-toggle-container">
          <div className="component-toggle-container">
            <div className="actions-toggle-label">
              Prompt members for Check-ins
            </div>
            <div className="daily-checkin-settings-container">
              {editOrganization.isLoading
                ? (
                <PuffLoader color="#fff" size={38} />
                  )
                : (
                <Switch
                  checked={currentOrganization?.dailyCheckin}
                  onChange={() =>
                    toggleDailyCheckins(!currentOrganization?.dailyCheckin)
                  }
                />
                  )}
              {currentOrganization?.dailyCheckin &&
                !editOrganization.isLoading && (
                  <div
                    onClick={() =>
                      editOrganizationRoleDailyCheckinAccessModal()
                    }
                    className="button secondary icon daily-checkin-person-icon"
                  >
                    <PersonAddIcon />
                  </div>
              )}
            </div>
          </div>
          {currentOrganization?.dailyCheckin && (
            <div>
              <div className="profile-settings-wrapper daily-checkin-form-container">
                <div className="profile-setting-container">
                  <h4 style={{ marginTop: '0.5rem' }}>
                    Check-in Days of the Week Selection
                  </h4>
                  {errorDayMessage && (
                    <div className="edit-error">{errorDayMessage}</div>
                  )}
                  <div className="daily-checkin-day-select-container">
                    {daysOfTheWeekDisplay}
                  </div>
                  <h4 style={{ marginTop: '2rem', marginBottom: '0.75rem' }}>
                    Check-in Questions
                  </h4>
                  {errorMessage && (
                    <div className="edit-error">{errorMessage}</div>
                  )}
                  <div data-testid="profile-settings-form">
                    {dailyCheckinFormFields}
                  </div>
                  {editDailyCheckins.isLoading
                    ? (
                    <PuffLoader color="#fff" size={38} />
                      )
                    : (
                    <div
                      className="button"
                      data-testid="edit-save-button"
                      onClick={() => {
                        void submitDailyCheckins()
                        void updateDailyCheckinDays()
                      }}
                      style={{ marginTop: '1rem', display: 'inline-block' }}
                    >
                      Save
                    </div>
                      )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default OrganizationSettingsActions
