import { sortOrgPlaylists } from '../../shared/utilities'
import './index.css'

function SpacePlaylistDropdown ({ orgPlaylists, setAttributeHandling, value, previousPlaylist }) {
  const removeNullValue = value === null ? '' : value

  return (
    <select disabled={previousPlaylist} value={removeNullValue} onChange={(e) => setAttributeHandling({ value: e.target.value, attribute: 'programVersionId' })}>
      <option value=''>Select Courses...</option>
      {sortOrgPlaylists(orgPlaylists)
        ?.filter(playlist => playlist?.enabled === true)
        ?.map((playlist) => {
          const courses = playlist?.programVersion?.program?.courses || []
          const displayName = courses.length > 1
            ? `${playlist?.programVersion?.program?.name} v${playlist?.programVersion?.version}.0.0 (Playlist)`
            : `${courses[0]?.name} v${courses[0]?.courseVersion?.[0]?.version}.0.0 (Course)`

          return (
            <option
              key={playlist?.programVersion?.id}
              value={playlist?.programVersion?.id}
            >
              {displayName}
            </option>
          )
        })}
    </select>
  )
}

export default SpacePlaylistDropdown
