import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { PuffLoader } from 'react-spinners'

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  to?: string;
  href?: string;
  className?: string;
  secondary?: boolean;
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
  testId?: string;
  variant?: 'primary' | 'secondary' | 'ghost';
  isDisabled?: boolean;
  style?: React.CSSProperties;
}

function Button ({
  children,
  to,
  href,
  onClick,
  className,
  secondary,
  size,
  isLoading,
  testId,
  variant,
  isDisabled,
  style,
  ...props
}: ButtonProps) {
  const classNames = [className, 'button']
  if (secondary || variant === 'secondary') classNames.push('secondary')
  if (size) classNames.push(size)
  if (variant !== 'secondary') classNames.push(variant)
  if (isDisabled) classNames.push('disabled')

  const nextClassName = clsx(classNames)

  const content = isLoading ? <PuffLoader color="#fff" size={18} /> : children

  if (to) {
    return (
      <Link
        to={to}
        className={nextClassName}
        data-testid={testId}
        {...props}
        onClick={isDisabled ? (e) => e.preventDefault() : undefined}
        aria-disabled={isDisabled}
        style={style}
      >
        {content}
      </Link>
    )
  }

  if (href) {
    return (
      <a
        href={href}
        className={nextClassName}
        data-testid={testId}
        {...props}
        onClick={isDisabled ? (e) => e.preventDefault() : undefined}
        aria-disabled={isDisabled}
        style={style}
      >
        {content}
      </a>
    )
  }

  return (
    <button
      className={nextClassName}
      onClick={onClick}
      data-testid={testId}
      disabled={isDisabled}
      {...props}
      style={style}
    >
      {content}
    </button>
  )
}

export default Button
