import { useParams } from 'react-router-dom'
import { useValidateOrganizationInviteCodeQuery } from '../../shared/queryHooks'
import LoadingScreen from '../../components/LoadingScreen'
import { logError } from '../../shared/logger'
import { config } from '../../shared/config'
import { generateCSRF } from '../../shared/utilities'
import './index.css'
import Logo from '../../components/Logo'

function OrganizationInvite () {
  const { inviteCode } = useParams()
  const hasInviteCode = !!inviteCode

  const {
    isLoading,
    isError,
    data: invite,
    error
  } = useValidateOrganizationInviteCodeQuery(inviteCode ?? '', {
    enabled: hasInviteCode
  })

  const isValidInviteCode = invite?.valid ?? false
  const organizationName = invite?.organization?.name ?? ''
  const organizationMembersTotal = invite?.organization?.memberCount ?? 0
  const organizationLogoUrl = invite?.organization?.logoUrl ?? ''

  function handleJoinCommunity () {
    localStorage.setItem('joinCommunityIntent', inviteCode ?? '')
    const csrf = generateCSRF()
    const url = `${config.API_HOST}/auth/login?nextUrl=${config.API_HOST}/sso&csrf=${csrf}`
    window.location.href = url
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isError) {
    logError(error)
  }

  if (!isValidInviteCode || isError) {
    return (
      <div
        style={{
          height: '90vh',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div className="invite-container">
          <div style={{ fontSize: '2rem', opacity: '0.6' }}>¯\_(ツ)_/¯</div>
          <div
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold'
            }}
          >
            Invite Invalid
          </div>
          <p style={{ opacity: '0.6' }}>
            This invite may be expired, or you might not have permission to join.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        height: '90vh',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <div className="invite-container">
        <div>
          <div
            style={{
              marginBottom: '1.5rem',
              opacity: '0.6',
              fontSize: '1rem'
            }}
          >
            You've been invited to join
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Logo
            logoUrl={organizationLogoUrl}
            name={organizationName}
            alt={'organization logo'}
          />
          <div
            style={{
              fontSize: '1.5rem',
              marginBottom: '1rem',
              fontWeight: 'bold'
            }}
          >
            {organizationName}
            </div>
          </div>
          <div style={{ opacity: '0.6', marginBottom: '4rem' }}>
            {`${organizationMembersTotal} member${
              organizationMembersTotal === 1 ? '' : 's'
            }`}
          </div>
        </div>
        <div
          className="button community-join-button"
          onClick={handleJoinCommunity}
        >
          Join Community
        </div>
      </div>
    </div>
  )
}

export default OrganizationInvite
