import { startOfWeek, addWeeks } from 'date-fns'
import { sortByActionType } from '../../shared/utilities'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Action from '../Action'
import './index.css'
import { Action as ActionType, Exercise } from '../../types'
import { parseDateTime } from '../../shared/dates'

interface AvailableActionsProps {
  actions?: ActionType[];
  organizationRoleId?: number;
  actionExercises?: Exercise[];
  tags?: any[];
}

function AvailableActions ({
  actions,
  organizationRoleId,
  actionExercises,
  tags
}: AvailableActionsProps) {
  const startOfNextWeek = addWeeks(startOfWeek(new Date()), 1)
  const endOfNextWeek = addWeeks(startOfNextWeek, 1)
  const uncompletedActions = actions?.filter(
    (action) => action?.status === 'UNCOMPLETED'
  )

  const nowActions = uncompletedActions
    ?.filter(
      (action) => parseDateTime(action?.dueBy) < startOfNextWeek.getTime()
    )
    .sort(sortByActionType)

  const nextActions = uncompletedActions
    ?.filter(
      (action) =>
        parseDateTime(action.dueBy) >= startOfNextWeek.getTime() &&
        parseDateTime(action.dueBy) <= endOfNextWeek.getTime()
    )
    .sort(sortByActionType)

  const laterActions = uncompletedActions
    ?.filter((action) => parseDateTime(action.dueBy) > endOfNextWeek.getTime())
    .sort(sortByActionType)

  return (
    <>
      <Accordion defaultExpanded={true} className="first">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Now
            <div style={{ opacity: 0.5 }}>
              {nowActions?.length ?? 0} actions
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {nowActions?.map((action) => (
            <Action
              key={action?.id}
              action={action}
              organizationRoleId={organizationRoleId}
              actionExercises={actionExercises}
              tags={tags}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Next Week{' '}
            <div style={{ opacity: 0.5 }}>
              {nextActions?.length ?? 0} actions
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {nextActions?.map((action) => (
            <Action
              key={action?.id}
              action={action}
              organizationRoleId={organizationRoleId}
              actionExercises={actionExercises}
              tags={tags}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            Later{' '}
            <div style={{ opacity: 0.5 }}>
              {laterActions?.length ?? 0} actions
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {laterActions?.map((action) => (
            <Action
              key={action?.id}
              action={action}
              organizationRoleId={organizationRoleId}
              actionExercises={actionExercises}
              tags={tags}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default AvailableActions
